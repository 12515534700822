import { Component, OnInit, Input } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { app, languageInitializer } from 'src/environments/environment';

@Component({
  selector: 'app-executive-summary-card',
  templateUrl: './executive-summary-card.component.html',
  styleUrls: ['./executive-summary-card.component.scss'],
})
export class ExecutiveSummaryCardComponent implements OnInit {
  @Input() commonData: any;
  @Input() searchTerm: any;
  x;
  directory = languageInitializer.directory;
  entryPoint = localStorage.entryPoint;
  constructor(
    private commonService: CommonService
  ) { }
  ngOnChanges() {
    this.x = this.commonData;

    if (app.entryPoint == 'WFT') {
      this.x["workingDurationCov"] = this.commonService.timeConverter(this.x.workingDuration, "display");
      this.x["outofzoneDurationCov"] = this.commonService.timeConverter(this.x.outofzoneDuration, "display");
    } else if (this.entryPoint == "ATM") {
      this.x["BatteryDrainDur"] = this.commonService.timeConverter(this.x.BatteryDrainDur, "display");
      this.x["LowBatteryDur"] = this.commonService.timeConverter(this.x.LowBatteryDur, "display");
      this.x["MovementDur"] = this.commonService.timeConverter(this.x.MovementDur, "display");
      this.x["PowerONDur"] = this.commonService.timeConverter(this.x.PowerONDur, "display");
      this.x["PowerCutDur"] = this.commonService.timeConverter(this.x.PowerCutDur, "display");
    } else {
      this.x["runningDurationCov"] = this.commonService.timeConverter(this.x.runningDuration, "display");
      this.x["stopDurationCov"] = this.commonService.timeConverter(this.x.stopDuration, "display");
      this.x["towedDurationCov"] = this.commonService.timeConverter(this.x.towedDuration, "display");
    }
    if (this.entryPoint != "ATM")
      this.x["idleDurationCov"] = this.commonService.timeConverter(this.x.idleDuration, "display");
  }


  splitNameArabic(name) {
    return this.commonService.splitNameArabic(name, this.directory)
  }

  changeToArabic(letter: String) {
    if (!letter)
      return ""
    return this.commonService.numberToArabic(letter.toString());
  }

  ngOnInit() {
    this.commonService.updateLanguageDir
      .subscribe(res => {
        this.directory = res == "" ? this.directory : res
      })
    this.x = this.commonData;

  }

}
