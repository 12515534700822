import { Component, Input, OnInit} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { Platform } from '@ionic/angular';
import { AjaxService } from 'src/app/services/ajax.service';
import { CommonService } from 'src/app/services/common.service';
import { serverUrl, storageVariable } from '../../../environments/environment';
@Component({
  selector: 'app-expense-form',
  templateUrl: './expense-form.component.html',
  styleUrls: ['./expense-form.component.scss'],
})
export class ExpenseFormComponent implements OnInit {
  @Input() value;
  maintenanceForm: FormGroup;
// expense:any;
 expense=["Tyre replace","Glass crash","Door crash","Breakdown","Others"]
isDeleteShow = false;
hideSerialNo = false;
vin = [];
vinPlateNo = [];
others=false;
update: string;
  myPlatform: any;
  constructor(
    private modalController: ModalController,
    private formBuilder: FormBuilder,
    public platform: Platform,
    private ajaxService: AjaxService,
    private commonService: CommonService,
  ) { }
  
  
  closeModel() {
    this.modalController.dismiss();
  }
  deleteModel(){


    
  }
  cancelBtn() {
    this.modalController.dismiss();
  }
  getExpenseType() {
    var url = serverUrl.web + '/login/getPreferences?key=Expense&companyId='+localStorage.getItem('corpId');
    this.ajaxService.ajaxGetPerference(url)
      .subscribe(res => {
        this.expense = res;
      })
  }
  submit(){
    const companyDetail = {
      branchID: localStorage.getItem('corpId'),
      companyID: localStorage.getItem('corpId'),
      userId: localStorage.getItem('userName')
    }
    var vin;
    var formData = this.maintenanceForm.value.plateNo;
    for (var i = 0; i < this.vinPlateNo.length; i++) {
      if (formData.toString() == (Object.keys(this.vinPlateNo[i])[0]).toString()) {
        vin = this.vinPlateNo[i][this.maintenanceForm.value.plateNo]
        break;
      }
    }
    if(this.maintenanceForm.value.expense === "Others" ){
      this.maintenanceForm.value.expense = this.maintenanceForm.value.others;
       }
  
var data={
      "vin":vin,
      "expense":this.maintenanceForm.value.expense,
      "content":this.maintenanceForm.value.others,
      "price":this.maintenanceForm.value.price,
      "fromDate":this.maintenanceForm.value.fromDate,
      "description":this.maintenanceForm.value.description,
      "createdBy":localStorage.getItem('userName'),
      "createdDt":"",
      "lastUpdBy":localStorage.getItem('userName'),
      "lastUpdDt":""
      
      }
if(this.update != 'available'){
        var url = serverUrl.web + '/expense/addExpense';
    }
      else{
         url = serverUrl.web + '/expense/updateExpense';
         data["id"] = this.value.id;
      }
      
      this.ajaxService.ajaxPostMethod(url,data).subscribe(res=>{
       
        if(res.message == "Added Successfully"){
          this.commonService.presentToast('Added Successfully')
          this.modalController.dismiss();
        }
        else if(res.message == "Updated Successfully"){
         this.modalController.dismiss();
         this.commonService.presentToast('Updated Successfully')
      
        }
        else{
          this.commonService.presentToast('Contact Support team')
        }
      })

  }

  createForm()
  {
    var now = new Date();
     var day = ("0" + now.getDate()).slice(-2);
     var month = ("0" + (now.getMonth() + 1)).slice(-2);
     var today = now.getFullYear() + "-" + (month) + "-" + (day);
     this.maintenanceForm = this.formBuilder.group({
      plateNo:['', Validators.required],
      price: ['', Validators.required],
      expense:['', Validators.required],
      others:[''],
      description:['', Validators.required],
      fromDate:[today, Validators.required],
    })
  }
  editForm()
  {
    if(this.value){
      this.update = "available";
      this.hideSerialNo = true;
      if(this.myPlatform != 'desktop'){
        this.isDeleteShow = true;
      }
     if(this.value.expense == this.value.content)
      {
        this.maintenanceForm.patchValue({
          plateNo:this.value.plateNo,
          price:this.value.price,
          expense:"Others",
          others:this.value.content,
          description:this.value.description,
          fromDate:this.value.fromDate
           });
           this.others = true;
      }
      else{
        if(this.value.expense !="Others"){
          this.value.content="";
        }
        this.maintenanceForm.patchValue({
          plateNo:this.value.plateNo,
          price:this.value.price,
          expense:this.value.expense,
          others:this.value.content,
          description:this.value.description,
          fromDate:this.value.fromDate
           });
          
      }
      }

  }
  changeExpenseType(ev) {
    if(ev.target.value === "Others")
    {
      this.others = true;
    }
    else if (ev.target.value === 'Tyre replace' || ev.target.value === 'Glass crash'|| ev.target.value === 'Door crash' || ev.target.value ===  "Breakdown") {
      this.others = false;
    }
    
  }
  ngOnInit() {
    this.myPlatform = this.platform.platforms()[0]
    if (this.myPlatform == 'tablet') {
      this.myPlatform = 'desktop';
    }
   this.getExpenseType();
     this.createForm();
     this.editForm();

    var data = storageVariable.dashboardData;
    for (var i = 0; i < Object.keys(data.liveDatas).length; i++) {
      const vin = Object.keys(data.liveDatas)[i];
      var obj = {};
      obj[data.liveDatas[vin]["plateNo"]] = data.liveDatas[vin]["vin"]
      this.vinPlateNo.push(obj);

      this.vin.push(data.liveDatas[vin]["plateNo"],); 
  }

}
}

