import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { languageInitializer, serverUrl, storageVariable } from 'src/environments/environment';
import { Platform, ModalController, AlertController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { jqxGridComponent } from 'jqwidgets-ng/jqxgrid';
import { AjaxService } from 'src/app/services/ajax.service';
import { CommonService } from 'src/app/services/common.service';
import { ExportExcelService } from '../../services/export-excel.service';
import { EmployeesModelComponent } from './employees-model/employees-model.component';

@Component({
  selector: 'app-employees',
  templateUrl: './employees.component.html',
  styleUrls: ['./employees.component.scss'],
})
export class EmployeesComponent implements OnInit {
  @ViewChild('employee', { static: false }) myGrid: jqxGridComponent;
  myPlatform: any;
  directory = languageInitializer.directory
  pdfdatas = [];
  localizationobj: {};
  freeFromObj: any = {};
  dataAdapter: any;
  selectedRow: any;
  workZoneName = [];
  freeformFenceList: any;
  source: { localdata: any; };
  columns: { text: string; datafield: string; cellsrenderer: (row: number, column: any, value: string) => string; }[];
  renderer: (row: number, column: any, value: string) => string;
  head = ["Employee ID", "Employee Name", "Employee Name In Arabic", "Device IMEINo", "Shift Name", "Work Zone", "Rest Day", "Note"]

  constructor(
    private modalController: ModalController,
    private ajaxService: AjaxService,
    private commonService: CommonService,
    private alertController: AlertController,
    private trans: TranslateService,
    public platform: Platform,
    private ete: ExportExcelService,
  ) { }

  myGridOnRowSelect($event) {
    console.log($event)
    this.selectedRow = $event.args.row;
  }

  async openAddEmployee() {
    let modal = await this.modalController.create({
      component: EmployeesModelComponent,
      cssClass: this.directory != 'rtl' ? "employees" : "employees-rtl",
      componentProps: {
        mode: 'add'
      }
    })
    modal.onDidDismiss().then(() => {
      this.myGrid.clearselection();
      this.getDatas();
      this.ajaxService.groupService()
    });
    return await modal.present();
  }

  async openEditEmployee(mode) {
    if (mode == 'edit' && this.selectedRow) {
      let modal = await this.modalController.create({
        component: EmployeesModelComponent,
        cssClass: this.directory != 'rtl' ? "employees" : "employees-rtl",
        componentProps: {
          mode: 'edit',
          selectedRow: this.selectedRow
        }
      })
      modal.onDidDismiss().then(() => {
        this.getDatas();
        this.ajaxService.groupService()
        this.myGrid.clearselection()
      });
      return await modal.present();
    } else
      this.commonService.presentToast("Please Select a Row")
  }


  async openDeleteEmployee() {
    if (this.selectedRow) {
      const alert = await this.alertController.create({
        header: 'Are you sure?',
        backdropDismiss: false,
        message: "Do you want to delete your Record?. If you delete it, your changes will be lost.",
        buttons: [{
          text: 'Cancel',
          role: 'cancel',
          handler: data => {
          }
        },
        {
          text: 'Ok',
          handler: datas => {
            this.commonService.presentLoader();
            var deleteArrayObj = [];
            let data = {};
            data["companyID"] = localStorage.corpId;
            data["branchID"] = localStorage.corpId;
            data["emailId"] = localStorage.userName;
            data["vin"] = this.selectedRow["vin"];
            data["freeForm"] = this.selectedRow["WorkZone"];
            data["shift"] = this.selectedRow["ShiftIds"];
            data["plateNo"] = this.selectedRow["EmployeeName"];
            data["imeiNo"] = this.selectedRow["DeviceIMEINo"];
            data["effFrom"] = "";
            deleteArrayObj.push(data);

            const url = serverUrl.web + '/VehicleController/delvehdetails';
            this.ajaxService.ajaxPostWithString(url, deleteArrayObj)
              .subscribe(res => {
                this.modalController.dismiss()
                this.commonService.presentToast("successfully deleted")
                this.getDatas();
                this.ajaxService.groupService()
                this.commonService.dismissLoader();
              }, err => {
                this.commonService.dismissLoader();
                this.modalController.dismiss()
                this.commonService.presentToast("Failed")
              });
          }
        }]
      });
      await alert.present();
    }
    else {
      this.commonService.presentToast('Please Select a Row to Delete');
      return "";
    }
  }

  getGeofenceData() {
    let url = serverUrl.web + "/Alert/GeozoneDatas?data=" + localStorage.corpId;
    this.ajaxService.ajaxGetJson(url).subscribe((res) => {
      if (res["Freeform"]) {
        this.freeFromObj = {};
        this.freeformFenceList = Object.values(res["Freeform"])
        for (let freefrom of this.freeformFenceList)
          this.freeFromObj[freefrom.id.split("-")[0]] = freefrom.name;
      }
    });
  }

  getDatas() {
    this.myPlatform = this.platform.platforms()[0];
    if (this.myPlatform == 'tablet')
      this.myPlatform = 'desktop';

    let url = serverUrl.web + "/VehicleController/getVehicleDetails"
    let data = {
      "companyId": localStorage.corpId,
      "branchId": localStorage.corpId,
      "userId": localStorage.userName,
      "dcName": "vehicleDisplayForEmpShift"
    }
    this.ajaxService.ajaxPostWithBody(url, data)
      .subscribe(res => {
        this.pdfdatas = [];
        for (var i = 0; i < res.length; i++) {
          res[i]["EmployeeNameArabic"] = (res[i]["EmployeeName"].includes("@,@")) ? res[i].EmployeeName.split("@,@")[1] : "---";
          res[i]["EmployeeName"] = (res[i]["EmployeeName"].includes("@,@")) ? res[i].EmployeeName.split("@,@")[0] : res[i]["EmployeeName"];
          if (this.freeFromObj) {
            var workZone = res[i].WorkZone.split(",")
            res[i]["WorkZoneName"] = ""
            workZone.map(data => res[i]["WorkZoneName"] += this.freeFromObj[data] + ",")
            res[i]["WorkZoneName"] = res[i].WorkZoneName.substring(0, res[i].WorkZoneName.length - 1)
          }
          this.pdfdatas.push([res[i].EmployeeID, res[i].EmployeeName, res[i].EmployeeNameArabic, res[i].DeviceIMEINo, res[i].ShiftName, res[i].WorkZoneName, res[i].RestDay, res[i].Note]);
        }
        this.source = { localdata: res };
        this.dataAdapter = new jqx.dataAdapter(this.source);
        this.columns = [
          { text: this.trans.instant('Employee ID'), datafield: 'EmployeeID', cellsrenderer: this.renderer },
          { text: this.trans.instant('Employee Name'), datafield: "EmployeeName", cellsrenderer: this.renderer },
          { text: this.trans.instant('Employee Name In Arabic'), datafield: "EmployeeNameArabic", cellsrenderer: this.renderer },
          { text: this.trans.instant('Device IMEINo'), datafield: 'DeviceIMEINo', cellsrenderer: this.renderer },
          { text: this.trans.instant('Shift Name'), datafield: 'ShiftName', cellsrenderer: this.renderer },
          { text: this.trans.instant('Work Zone'), datafield: 'WorkZoneName', cellsrenderer: this.renderer },
          { text: this.trans.instant('Rest Day'), datafield: 'RestDay', cellsrenderer: this.renderer },
          { text: this.trans.instant('Note'), datafield: 'Note', cellsrenderer: this.renderer },
        ]
        this.commonService.dismissLoader();
      })
  }

  async createPdf() {
    this.commonService.createPdf(this.head, this.pdfdatas,null, "Employees Details", this.myPlatform, "Employees Details", null)
  }

  exportToExcel() {
    let shiftdata = {
      title: 'Employees Details',
      data: this.pdfdatas,
      headers: this.head
    }
    this.ete.exportExcel(shiftdata);
  }

  duplicateDataPresisted() {
    this.source = { localdata: "" };
    this.dataAdapter = new jqx.dataAdapter(this.source);
    this.columns = [
      { text: this.trans.instant('Employee ID'), datafield: 'Employee ID', cellsrenderer: this.renderer },
      { text: this.trans.instant('Employee Name'), datafield: "EmployeeName", cellsrenderer: this.renderer },
      { text: this.trans.instant('Employee Name In Arabic'), datafield: "EmployeeNameArabic", cellsrenderer: this.renderer },
      { text: this.trans.instant('Device IMEINo'), datafield: 'DeviceIMEINo', cellsrenderer: this.renderer },
      { text: this.trans.instant('Shift Name'), datafield: 'Shift Name', cellsrenderer: this.renderer },
      { text: this.trans.instant('Work Zone'), datafield: 'Work Zone', cellsrenderer: this.renderer },
      { text: this.trans.instant('Rest Day'), datafield: 'Rest Day', cellsrenderer: this.renderer },
      { text: this.trans.instant('Note'), datafield: 'Note', cellsrenderer: this.renderer },
      { text: this.trans.instant('Serial NO'), datafield: 'Serial NO', cellsrenderer: this.renderer },
    ]
  }

  ngOnInit() {
    this.duplicateDataPresisted();
    this.getGeofenceData();
    this.commonService.presentLoader();
    setTimeout(res => {
      this.getDatas();
      this.commonService.dismissLoader();
    }, 500)

    this.localizationobj = {
      groupsheaderstring: this.trans.instant("Drag a column and drop it here to group by that column"),
    }
    this.commonService.updateLanguageDir
      .subscribe(res => {
        this.localizationobj = { groupsheaderstring: "" }
        this.directory = res == "" ? this.directory : res
        this.columns = [
          { text: this.trans.instant('Employee ID'), datafield: 'EmployeeID', cellsrenderer: this.renderer },
          { text: this.trans.instant('Employee Name'), datafield: "EmployeeName", cellsrenderer: this.renderer },
          { text: this.trans.instant('Employee Name In Arabic'), datafield: "EmployeeNameArabic", cellsrenderer: this.renderer },
          { text: this.trans.instant('Device IMEINo'), datafield: 'DeviceIMEINo', cellsrenderer: this.renderer },
          { text: this.trans.instant('Shift Name'), datafield: 'ShiftName', cellsrenderer: this.renderer },
          { text: this.trans.instant('Work Zone'), datafield: 'WorkZoneName', cellsrenderer: this.renderer },
          { text: this.trans.instant('Rest Day'), datafield: 'RestDay', cellsrenderer: this.renderer },
          { text: this.trans.instant('Note'), datafield: 'Note', cellsrenderer: this.renderer },
        ]
        this.localizationobj = {
          groupsheaderstring: this.trans.instant("Drag a column and drop it here to group by that column"),
        }
        if (this.myGrid)
          this.myGrid.updatebounddata();
      })
    this.ajaxService.groupService()
  }

}
