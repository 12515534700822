import { Component, OnInit } from '@angular/core';
import { AjaxService } from 'src/app/services/ajax.service';
import { CommonService } from 'src/app/services/common.service';
import { companyReportData, serverUrl } from 'src/environments/environment';

@Component({
  selector: 'app-common-report',
  templateUrl: './common-report.component.html',
  styleUrls: ['./common-report.component.scss'],
})
export class CommonReportComponent implements OnInit {
  data: any;
  header = []
  selectedTab = "Summary"
  gridData: any;
  constructor(
    private commonService: CommonService,
    private ajaxService: AjaxService
  ) { }
  changeTab(head) {
    this.selectedTab = head
    this.gridData = this.data[head];
  }
  getAddress(data) {
    const url = serverUrl.web + "/login/company/latlngtoaddress/" + data["Violation Place"].split(",")[0] + "/" + data["Violation Place"].split(",")[1] + "/" + localStorage.corpId;
    this.ajaxService.ajaxGetObject(url)
      .subscribe(res => {
        data.location = res;
      });

  }
  ngOnInit() {
    this.data = companyReportData.reportData
    this.header = Object.keys(this.data)
    this.selectedTab = this.header.includes("Summary") ? "Summary" : this.header[0]
    this.gridData = this.data[this.selectedTab]
    console.log(this.gridData)
    this.commonService.dismissLoader();
  }

  changeToArabic(letter) {
    return this.commonService.numberToArabic(letter.toString());
  }

}
