import { Component, OnInit, ViewChild, Input, AfterViewInit} from '@angular/core';
import { AuthMapService } from '../../services/auth-map.service';
import { GoogleMapService } from '../../services/google-map.service';
import { OpenlayerMapService } from '../../services/openlayer-map.service';
import { CommonService } from '../../services/common.service';
import { AjaxService } from '../../services/ajax.service';
import { app, languageInitializer, serverUrl, storageVariable } from 'src/environments/environment';
import { DatePipe } from '@angular/common';
import { ModalController, NavParams, Platform } from '@ionic/angular';
import Overlay from 'ol/Overlay';
import { jqxGridComponent } from 'jqwidgets-ng/jqxgrid';
import { ActivatedRoute, Router } from '@angular/router';
import { jqxComboBoxComponent } from 'jqwidgets-ng/jqxcombobox';
// import { language } from 'src/app/services/language';
import { TranslateService } from '@ngx-translate/core';
// import { TrackDistanceComponent } from 'src/app/components/track-distance/track-distance.component';
// import { log } from 'util';
import { Clipboard } from '@ionic-native/clipboard/ngx';
import { animate, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'app-vts-trackhistory',
  templateUrl: './vts-trackhistory.component.html',
  styleUrls: ['./vts-trackhistory.component.scss'],
  providers: [DatePipe],
  animations: [trigger('fadeIn', [transition(':enter', [
    style({
      opacity: 0
    }),
    animate('1s ease-in', style({ opacity: 1 }))
  ])]), trigger('fadeOut', [transition(':leave', [
    style({
      opacity: 1,
    }),
    animate('1s ease-out', style({ opacity: 0 }))
  ])])]
})
export class VtsTrackhistoryComponent implements OnInit, AfterViewInit {

  @ViewChild('mapElement', { static: false }) mapElement;
  @ViewChild('myGrid', { static: false }) myGrid: jqxGridComponent;
  @ViewChild("plateNoComobox", { static: false }) plateNoComobox: jqxComboBoxComponent;
  header: HTMLElement;
  directory = languageInitializer.directory
  btns: HTMLCollectionOf<Element>;
  header1: HTMLElement;
  btns1: HTMLCollectionOf<Element>;
  trackIdDis: string = "none";
  trackAlertDis: string = "none";
  mobileTrackDiv: string = "block";
  traveVinData;
  show = true;
  showHistoryCard = false;
  stopTrackHistoryArray = [];
  timeStampArray = [];
  maxSpeed = [];
  odometerArray = []
  cardShow: boolean = false;
  multiURL: boolean = false;
  mobileDate: any;
  dateTime: any;
  colorTrack: any;
  plateCard: string = ""
  replacedtoDate: any;
  replacedfromDate: any;
  showdataMarker = {
    stop: true,
    idle: true,
    running: false,
    overSpeed: false,
    towed: false,
    outofzone: true,
    working: false,
    poweron: true,
    powercut: true,
    lowbattery: false,
    BatteryDrain: false,
    movement: false
  }
  color = {
    'Idle': '#1f5baa',
    'Running': '#1eb15d',
    'Stop': '#FF0000',
    'Yet_to_transmit': '#7d410f',
    'No Transmission': '#000000',
    'Online': '#00E1BC',
    'Overspeed': '#f28918',
    'DoorOpen': '#FF851B',
    'HighTemp': '#FF0000',
    "PowerFail": '#412525',
    'Geofence': '#1f5baa',
    'Good': '#1eb15d',
    'Towed': '#ec268f',
    'StandBy': '#f4d642',
    "Working": "#1eb15d",
    "Out_Of_Zone": "#FF0000"
  }
  radio: String = ""
  popupView = false;
  popUp: any;
  trackHistoryCurrentRes: any;
  source: { localdata: any; };
  renderer: (row: number, column: any, value: string) => string;
  tableData: any;
  dataAdapter: any;
  columns: (void | { text: string; datafield: string; cellsrenderer: any; })[];
  userSelected: any = {
    "vin": "",
    "fromDate": "",
    "toDate": "",
    "fromTime": "",
    "toTime": ""
  };
  showPlateNo: boolean = true;
  plateNoList: any[];
  plateNoSelected: any = "";
  selectedLayout: any = "map";
  showHideRangeArrow: boolean = false;
  plateNos: any;
  entrypoint: any;
  geofenceCreatedList: any[];
  dashboardJson = [];
  data: any;
  allAlts: any[] = [];
  showData: any;
  intervalvalues: any = "Minutes";
  ngStop: any = 0;
  minutes: number = 0;
  seperateUrlLogin: Boolean = false;
  urlpresentAlert: boolean = true;
  Gridview: boolean = false;
  Gridview1: boolean = false;
  platWidth: string = '23%';
  platMargin: string = "2%";
  arrayTrack = [];
  vins: any
  toggleShow: boolean = false;
  statusVehicle: boolean = false;
  hideDist: boolean = false;
  MaxDate;
  pageSelector: any;
  gridheight: number;
  gpsEnable: boolean = false;
  gsmEnable: boolean = false;
  isTeltonika: boolean = false;
  vehoerationcode: any[] = [];
  vehoerationcodesourcs: any[] = [];
  callsign: any;
  callsignfilter: any;
  convertAddress :any;

  constructor(
    public mapService: AuthMapService,
    private datePipe: DatePipe,
    private commonService: CommonService,
    private ajaxService: AjaxService,
    private platform: Platform,
    private router: Router,
    private trans: TranslateService,
    private modalController: ModalController,
    private clipboard: Clipboard,
    private route: ActivatedRoute
  ) {
    if (localStorage.map == "GoogleMap") {
      this.mapService = new GoogleMapService();
    }
    else {
      this.mapService = new OpenlayerMapService();
    }
    this.MaxDate = new Date().toISOString().slice(0, 16);
  }
  ngAfterViewInit() {
    if (this.commonService.myPlatform != "desktop") {
      if (this.seperateUrlLogin)
        this.seprateCard('none')
    }
  }
  ionViewWillEnter() {
    this.popUp = (res) => {
    }
    setTimeout(() => {
      this.map.setTarget(this.mapElement.nativeElement);
      // this.plateNoComobox.selectItem("TEJARAT7560")
    }, 2000);
  }

  map: any;
  selectedVin: any = {};
  @Input() gridLiveChange;
  @Input() type
  user: any = {};
  selectedValue = 'today';
  displayPlay: boolean;
  displayPause: boolean;
  displayStop: boolean;
  liveCount;
  showSwipeModal: boolean = true;
  trackHistoryJson = [];
  playback;
  playbackIcon;
  interval;
  markerAddress;
  display = "play";
  playbackUpdater = {
    status: false,
    "timeStamp": "",
    "speed": "",
    "odometer": ""
  }
  markerInfo = {
    "show": false,
    "status": "",
    "speed": '',
    "duration": '',
    "timeStamp": ''
  };
  DateDesktop: any;
  range = '62';
  playBackSpeed = 1000;
  playBackOdometer: number = 0;
  hide: boolean = false;
  createMap() {
    setTimeout(() => {
      const defaultLocation: any = localStorage.getItem("defaultLocation");
      const [latitudeStr, longitudeStr]: string[] = defaultLocation.split(',');
      const latitude: number = parseFloat(latitudeStr);
      const longitude: number = parseFloat(longitudeStr);
      if (this.gridLiveChange == undefined || this.map == undefined) {
        this.map = this.mapService.loadMap(this.mapElement.nativeElement, { lat: this.traveVinData.latitude, lng: this.traveVinData.longitude }, false, false);
        if (defaultLocation != "") {
          this.mapService.setCenter(this.map, { lat: latitude, lng: longitude });
        } else {
          this.mapService.setCenter(this.map, { lat: 24.698619318986992, lng: 46.704241488514064 });
        }
      }
      if (this.commonService.myPlatform == "desktop" && this.type != "Multi Track") {
        this.getInBetweenDate()
      } else {
        this.getHistory();
      }
    }, 500);

  }

  getAddress(lat, lng) {
    const url = serverUrl.web + "/login/company/latlngtoaddress/" + lat + "/" + lng + "/" + localStorage.corpId;
    this.ajaxService.ajaxGetObject(url)
      .subscribe(res => {
        if (res.length > 5) {
          this.markerAddress = res;
        }
        else {
          this.markerAddress = "---";
        }
      });
  }
  toggleView() {
    this.showSwipeModal = !this.showSwipeModal;
    setTimeout(() => {
      if(this.plateNoComobox != undefined)
      this.plateNoComobox.selectItem(this.selectedVin.vin)
    }, 500);
    if (this.pageSelector == 'Multi Track' )
      setTimeout(() => {
        if (this.myGrid != undefined)
          this.myGrid.columnsheight(15)
      }, 1000);
  }

  closemodal() {
    this.modalController.dismiss()
  }
  toggleViews() {
    this.toggleShow = !this.toggleShow;

  }

  trackModal(selectedData) {
    let date = new Date();
    let toTime = new Date(date.getTime() - (date.getTimezoneOffset() * 60000)).toJSON();
    let url;
    this.selectedValue = selectedData;
    if (typeof (selectedData) == "number") {
      date.setHours(date.getHours() - selectedData);
      this.user = {
        "fromDate": new Date(date.getTime() - (date.getTimezoneOffset() * 60000)).toJSON(),
        "toDate": toTime.split(".")[0],
      }
    } else {
      let fromTime = '00:00:00'
      if (selectedData === 'today') {
        this.user = {
          "fromDate": (new Date(date.getTime() - (date.getTimezoneOffset() * 60000)).toJSON()).split('T')[0] + 'T' + fromTime,
          "toDate": toTime.split("T")[0] + 'T' + date.toTimeString().split(' ')[0],
        }
      } else if (selectedData === 'yesterday') {
        let yesterday = new Date(date.setDate(date.getDate() - 1));
        this.user = {
          "fromDate": (new Date(yesterday.getTime() - (yesterday.getTimezoneOffset() * 60000)).toJSON()).split('T')[0] + 'T' + fromTime,
          "toDate": (new Date(yesterday.getTime() - (yesterday.getTimezoneOffset() * 60000)).toJSON()).split('T')[0] + 'T23:59:59'
        }
      }
      // this.MaxDate=this.user.fromDate

    }
  }
  track(mode) {
    if (mode == 'stop') {
      this.showdataMarker.stop = !this.showdataMarker.stop;
      this.stopColor()
    } else if (mode == 'idle') {
      this.showdataMarker.idle = !this.showdataMarker.idle
    } else if (mode == 'overspeed') {
      this.showdataMarker.overSpeed = !this.showdataMarker.overSpeed
    } else if (mode == 'running') {
      this.showdataMarker.running = !this.showdataMarker.running
    } else if (mode == 'towed') {
      this.showdataMarker.towed = !this.showdataMarker.towed
    } else if (mode == 'outofzone') {
      this.showdataMarker.outofzone = !this.showdataMarker.outofzone
    } else if (mode == 'working') {
      this.showdataMarker.working = !this.showdataMarker.working
    } else if (mode == 'poweron') {
      this.showdataMarker.poweron = !this.showdataMarker.poweron
    } else if (mode == 'powercut') {
      this.showdataMarker.powercut = !this.showdataMarker.powercut
    } else if (mode == 'lowbattery') {
      this.showdataMarker.lowbattery = !this.showdataMarker.lowbattery
    } else if (mode == 'BatteryDrain') {
      this.showdataMarker.BatteryDrain = !this.showdataMarker.BatteryDrain
    } else if (mode == 'movement') {
      this.showdataMarker.movement = !this.showdataMarker.movement
    }
    console.log("showdataMarker",this.showdataMarker);
    if((this.gpsEnable) && (!this.showdataMarker.stop || !this.showdataMarker.idle || !this.showdataMarker.running || !this.showdataMarker.overSpeed || !this.showdataMarker.towed)){
      this.strength("gps")
    }else if((this.gsmEnable) && (!this.showdataMarker.stop || !this.showdataMarker.idle || !this.showdataMarker.running || !this.showdataMarker.overSpeed || !this.showdataMarker.towed)){
      this.strength("gsm")
    }
    
    this.mapService.clearLayers(this.map, [1])
    this.plotMarkers("")
    this.plotChangeMarker();
  }



  stopColor() {
    let colorArray = ['grey', 'blue', 'brown', 'orange', 'indigo', 'green', 'purple', 'red', 'white'];
    if (this.intervalvalues == "Hours") {
      this.minutes = this.ngStop * 60
    }
    else {
      this.minutes = this.ngStop
    }
    this.arrayTrack = [];
    if (sessionStorage.color && this.seperateUrlLogin) {

      this.mapService.createLine_stop(this.map, this.stopTrackHistoryArray, sessionStorage.color);
    }
    else {
      this.mapService.createLine(this.map, this.stopTrackHistoryArray);
    }
    if (!this.showdataMarker.stop || this.statusVehicle) {
      this.cardShow = false;
      return null;
    }
    let startIndex;
    let json = this.trackHistoryJson;
    let startEndIndex = [];
    var stop: boolean = false;
    for (let cnt = 1; cnt < this.trackHistoryJson.length - 1; cnt++) {
      startIndex = undefined;
      let source = {
        lat: parseFloat(json[cnt]['latitude']),
        lng: parseFloat(json[cnt]['longitude'])
      };

      let icon = 'assets/trackHistoryIcons/oldblue.png';
      const diffMin = this.commonService.secondstoHHMMSSCal(
        new Date(json[cnt + 1]['timeStamp']), new Date(json[cnt]['timeStamp']));
      if (json[cnt]['vehicleStatus'] == "Stop" && this.showdataMarker.stop == true) {
        json[cnt]["duration"] = diffMin;
        var a = diffMin.split(':');
        var minutes = (+a[0]) * 60 + (+a[1]);
        if (this.ngStop == 0) {
          this.cardShow = false;
          return null;
        }
        else if (this.minutes <= minutes) {
          stop = true;
          startIndex = cnt;
        }
      }
      if (startIndex != null && startIndex != undefined)
        startEndIndex.push(startIndex)
    }
    let end = startEndIndex.length;
    let slicedArr = [];
    let j = 0;
    if (startEndIndex.length !== 0) {
      for (let i = 0; i < end; i++) {
        slicedArr = this.stopTrackHistoryArray.slice(startEndIndex[i], startEndIndex[i + 1]);
        this.mapService.createLine_stop(this.map, slicedArr, colorArray[j]);
        if (i + 1 >= end && slicedArr.length < 20) {
          break;
        }
        let timeStamp = this.timeStampArray.slice(startEndIndex[i], startEndIndex[i + 1]);
        timeStamp.shift();
        const difference = new Date(`2000-01-01 ${this.timeStampArray[startEndIndex[i + 1]] == undefined ? this.datePipe.transform(this.user.toDate, 'h:mm:ss a') : this.timeStampArray[startEndIndex[i + 1]]}`).getTime() - new Date(`2000-01-01 ${timeStamp[0]}`).getTime();
        const seconds = Math.floor(difference / 1000);
        const minutes = Math.floor(seconds / 60);
        const hours = Math.floor(minutes / 60);
        let time = (isNaN(difference))?'---': `${hours}:${minutes % 60}:${seconds % 60}`;
        let odometer = this.odometerArray.slice(startEndIndex[i], startEndIndex[i + 1])
        let GrossDist = odometer.reduce((a, b) => a + b);
        GrossDist = (GrossDist > 0 && GrossDist < 1) ? GrossDist.toFixed(3) : parseInt(GrossDist)
        let maxSpeed = this.maxSpeed.slice(startEndIndex[i], startEndIndex[i + 1]);
        this.arrayTrack.push({ color: colorArray[j], startTime: timeStamp[0], endTime: this.timeStampArray[startEndIndex[i + 1]] == undefined ? this.datePipe.transform(this.user.toDate, 'h:mm:ss a') : this.timeStampArray[startEndIndex[i + 1]], duration: time, maxSpeed: Math.max(...maxSpeed), distance: GrossDist })
        if (j < 7) {
          j++;
        } else {
          j = 0;
        }
      }
    }
    if (this.trackHistoryJson.length !== 0 && this.arrayTrack.length !== 0) {
      this.hide = this.commonService.myPlatform != 'desktop' ? false : this.hide;
      this.cardShow = true;
      this.plateCard = this.selectedVin.plateNo
    }
    else {
      this.cardShow = false;
    }
  }


  copylinK(start, end, color) {
    this.multiURL = true;
    this.colorTrack = color;
    const date1 = new Date(`1/1/2000 ${start}`);
    date1.setSeconds(date1.getSeconds() - 1);
    const startTime = this.datePipe.transform(date1, 'HH:mm:ss');
    const date2 = new Date(`1/1/2000 ${end}`);
    date1.setSeconds(date2.getSeconds() - 1);
    const endTime = this.datePipe.transform(date2, 'HH:mm:ss');
    this.dateTime = this.userSelected.fromDate.split(" ")[0] + "T" + startTime + "#" + this.userSelected.toDate.split(" ")[0] + "T" + endTime
    let date = this.dateTime.split('#');
    this.mobileDate = this.DateDesktop + date[0] + "#" + this.DateDesktop + date[1]
    this.token()
  }

  toggleCard(data) {
    if (data == "open") {
      if (this.trackHistoryJson.length !== 0 && this.arrayTrack.length !== 0) {
        this.cardShow = true;
      }
    } else if (data == "close") {
      this.cardShow = false;
    }
  }
  changePlateNo() {
    if (this.plateNoComobox.getSelectedItem() != null) {

      let vin = this.plateNoComobox.getSelectedItem().value
      this.selectedVin = storageVariable.dashboardData.liveDatas[vin];
      this.traveVinData = storageVariable.dashboardData.liveDatas[vin];

      if (this.selectedVin.vin != vin)
        this.getInBetweenDate();
    }
  }


  switchMap(data, map) {
    this.selectedLayout = map;
    this.map.getLayers().item(3).setVisible(data);
      this.map.getLayers().item(0).setVisible(!data) 
  }
  generateDateRanges(fromDate: string, fromTime: string, toDate: string, toTime: string): string[] {
    const startDate = new Date(`${fromDate} ${fromTime}`);
    const endDate = new Date(`${toDate} ${toTime}`);
    const result: string[] = [];

    let currentDate = new Date(startDate);

    while (currentDate < endDate) {
      const startOfDay = new Date(currentDate);
      const endOfDay = new Date(currentDate);
      endOfDay.setHours(23, 59, 59, 999);

      if (endOfDay > endDate) {
        endOfDay.setHours(endDate.getHours(), endDate.getMinutes(), endDate.getSeconds(), endDate.getMilliseconds());
      }

      result.push(`${this.formatDate(startOfDay)}#${this.formatDate(endOfDay)}`);
      
      // Move to the next day
      currentDate.setDate(currentDate.getDate() + 1);
      currentDate.setHours(0, 0, 0, 0);
    }

    return result;
  }

  private formatDate(date: Date): string {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = date.getHours() % 12 || 12;
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
    const ampm = date.getHours() < 12 ? 'AM' : 'PM';
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds} ${ampm}`;
  }
  getInBetweenDate() {
    // this.closeDiv("track-id");
    // this.closeDiv("track-Alert");
    this.vins = this.selectedVin.vin;
    this.commonService.presentLoader();
    this.display = "play";
    this.moveVehicleInMap('stop', '');
    if (this.mapService)
      this.mapService.clearLayers(this.map, [1, 2])
   // const url = serverUrl.web + '/trackHistory/trackhistoryVTS';
    // const body = {
    //   "vin": this.selectedVin.vin
    //   , "fromDate": this.datePipe.transform(this.user.fromDate, 'yyyy-MM-dd'),
    //   "toDate": this.datePipe.transform(this.user.toDate, 'yyyy-MM-dd'),
    //   "fromTime": this.datePipe.transform(this.user.fromDate, 'h:mm:ss a'),
    //   "toTime": this.datePipe.transform(this.user.toDate, 'h:mm:ss a')
    // }
    const dateRanges = this.generateDateRanges(this.datePipe.transform(this.user.fromDate, 'yyyy-MM-dd'), this.datePipe.transform(this.user.fromDate, 'h:mm:ss a'),
    this.datePipe.transform(this.user.toDate, 'yyyy-MM-dd'), this.datePipe.transform(this.user.toDate, 'h:mm:ss a'));
    console.log(dateRanges);
    // // this.MaxDate=this.user.fromDate
    // this.ajaxService.ajaxPostMethod(url, body)
    //   .subscribe(res => {


        if (dateRanges.length > 0) {
          this.urlpresentAlert = false;
          let data = []

          for (let i = 0; i < dateRanges.length; i++) {
            data.push({ "From": dateRanges[i].split('#')[0], "To": dateRanges[i].split('#')[1] })
          }
          this.renderer = (row: number, column: any, value: string,) => {
            if (value == "" || null || undefined) {
              return "----"
            }
            else {
              return '<span style="line-height:32px;font-size:11px;color:darkblue;margin:auto;padding:0px 5px">' + value + '</span>';
            }
          }
          this.source = { localdata: data };
          this.dataAdapter = new jqx.dataAdapter(this.source);
          this.columns = [
            { text: this.trans.instant("From Date"), datafield: 'From', cellsrenderer: this.renderer },
            { text: this.trans.instant("To Date"), datafield: 'To', cellsrenderer: this.renderer }
          ]
          this.myGrid.updatebounddata();
          setTimeout(() => {
            this.myGrid.selectrow(0)

          }, 1000);
          if (this.pageSelector == 'Multi Track')
            setTimeout(() => {
              this.myGrid.columnsheight(15)
            }, 1000);
        } else {
          this.trackHistoryJson = [];
          this.cardShow = false;
          this.closeDiv("track-id");

          if (this.commonService.myPlatform == "desktop") {
            this.userSelected.fromDate = this.datePipe.transform(this.user.fromDate, 'yyyy-MM-dd') + " " + this.datePipe.transform(this.user.fromDate, 'HH:mm:ss')
            this.userSelected.toDate = this.datePipe.transform(this.user.toDate, 'yyyy-MM-dd') + " " + this.datePipe.transform(this.user.toDate, 'HH:mm:ss')
            if (this.type == "Multi Track")
              this.commonService.presentAlert("No data", "Data not available" + " For " + this.selectedVin.plateNo);
            else
              this.commonService.presentAlert("No data", "Data not available");
            this.urlpresentAlert = false;
          }
          // else{this.urlpresentAlert = false} 
          this.source = { localdata: [] };
          this.dataAdapter = new jqx.dataAdapter(this.source);
          this.commonService.dismissLoader()
        }
      // })

  }
  createFreeForm(selectedRow) {
    let data = selectedRow.latlng;
    let latLngData = [];
    if (selectedRow.latlng) {
      for (let i in data) {
        var latlng = data[i];
        latLngData.push({
          latitude: latlng.split(",")[0],
          longitude: latlng.split(",")[1],
        });
      }
      latLngData.push({
        latitude: data[0].split(",")[0],
        longitude: data[0].split(",")[1],
      });
      this.mapService.setRoadLineString(this.map, latLngData);
    }
  }
  myGridOnRowSelect($event) {
    this.closeDiv("track-id")
    this.closeDiv("track-Alert")
    this.commonService.presentLoader();
    if ($event.args.row == undefined) {
      this.userSelected.fromDate = this.datePipe.transform(this.user.fromDate, 'yyyy-MM-dd') + " " + this.datePipe.transform(this.user.fromDate, 'HH:mm:ss')
      this.userSelected.toDate = this.datePipe.transform(this.user.toDate, 'yyyy-MM-dd') + " " + this.datePipe.transform(this.user.toDate, 'HH:mm:ss')

    } else {
      this.userSelected.fromDate = this.datePipe.transform($event.args.row.From, 'yyyy-MM-dd') + " " + this.datePipe.transform($event.args.row.From, 'HH:mm:ss')
      this.userSelected.toDate = this.datePipe.transform($event.args.row.To, 'yyyy-MM-dd') + " " + this.datePipe.transform($event.args.row.To, 'HH:mm:ss')
    }
    this.getHistory()
  }

  downloadKML() {
    let url = serverUrl.web + "/trackHistory/generateKMLServlet?name=BackTracking&selectedVehicle=" + this.selectedVin.vin +
      "&fromDate=" + this.datePipe.transform(this.userSelected.fromDate, 'yyyy-MM-dd') + " " + this.datePipe.transform(this.userSelected.fromDate, 'HH:mm:ss')
      + "&toDate=" + this.datePipe.transform(this.user.toDate, 'yyyy-MM-dd') + " " + this.datePipe.transform(this.userSelected.toDate, 'HH:mm:ss') +
      "&compName=" + localStorage.corpId + "&brnchName=" + localStorage.corpId + "&userName=" + localStorage.userName
      + "&selectedVehiPlateNo=" + this.selectedVin.plateNo + '&entryPoint=' + app.entryPoint
    this.ajaxService.ajaxGetObject(url).subscribe(res => {
      if (res == "") {
        this.commonService.presentToast("your data is empty, unable to download empty data.")
      } else {
        var hiddenElement = document
          .createElement('a');
        hiddenElement.href = 'data:text/kml;charset=utf-8,' + encodeURIComponent(res);
        hiddenElement.target = '_blank';
        hiddenElement.download = "KML_" + this.selectedVin.plateNo + "_" + this.datePipe.transform(this.userSelected.fromDate, 'yyyy-MM-dd') + "_" + this.datePipe.transform(this.user.toDate, 'yyyy-MM-dd') + ".kml";
        hiddenElement.click();
      }
    })

  }
showPinDetails(feature,evt,arg3){
  (arg3 == null) ? this.convertAddress = feature.values_.name.address: this.convertAddress = arg3;
  const container = document.getElementById('popup');
  const content = document.getElementById('popup-content');
  const closer = document.getElementById('popup-closer');
  const overlay = new Overlay({
    element: container,
    autoPan: true,
    autoPanAnimation: {
      duration: 0
    }
  });
  let T1 = feature.values_.name.temp1 != "" ? ("TEMP1 :" + feature.values_.name.temp1) + ';' : "",
    T2 = feature.values_.name.temp2 != "" ? ("TEMP2 :" + feature.values_.name.temp2) + ';' : "",
    T3 = feature.values_.name.temp3 != "" ? ("TEMP3 :" + feature.values_.name.temp3) + ';' : "",
    T4 = feature.values_.name.temp4 != "" ? ("TEMP4 :" + feature.values_.name.temp4) + ';' : "",
    BT1 = feature.values_.name.bltemp1 != "" ? ("BTEMP1 :" + feature.values_.name.bltemp1) + ';' : "",
    BT2 = feature.values_.name.bltemp2 != "" ? ("BTEMP2 :" + feature.values_.name.bltemp2) + ';' : "",
    BT3 = feature.values_.name.bltemp3 != "" ? ("BTEMP3 :" + feature.values_.name.bltemp3) + ';' : "",
    BT4 = feature.values_.name.bltemp4 != "" ? ("BTEMP4 :" + feature.values_.name.bltemp4) + ';' : "",
    BH1 = feature.values_.name.blueHumidity1 != "" ? ("HUMIDITY1 :" + feature.values_.name.blueHumidity1) + ';' : "",
    BH2 = feature.values_.name.blueHumidity2 != "" ? ("HUMIDITY2 :" + feature.values_.name.blueHumidity2) + ';' : "",
    BH3 = feature.values_.name.blueHumidity3 != "" ? ("HUMIDITY3 :" + feature.values_.name.blueHumidity3) + ';' : "",
    BH4 = feature.values_.name.blueHumidity4 != "" ? ("HUMIDITY4 :" + feature.values_.name.blueHumidity4) + ';' : "";
  let t = [T1, T2, T3, T4, BT1, BT2, BT3, BT4, BH1, BH2, BH3, BH4];
   this.map.addOverlay(overlay);
  const coordinate = evt.coordinate;
  content.innerHTML = '<h2 style="margin-bottom: -26px;border: 1px solid black;margin-top: -1px;background-color: lightgrey;text-transform: uppercase;text-align: center;">' +
    (app.entryPoint == "WFT" && feature.values_.name.vehicleStatus == "Towed" ? this.trans.instant("Idel out of zone") : this.trans.instant(feature.values_.name.vehicleStatus)) + '</h2><br>';
  if (feature.values_.name.vehicleStatus == 'Idle' || feature.values_.name.vehicleStatus == 'Stop' || feature.values_.name.vehicleStatus == 'Out_Of_Zone')
    content.innerHTML += '<p style="text-transform: uppercase">' + this.trans.instant(feature.values_.name.vehicleStatus) + ' ' + this.trans.instant('FOR') + ': ' + this.commonService.numberToArabic(feature.values_.name.duration);

  content.innerHTML += '<p>' + this.trans.instant('TIME') + ':' + this.commonService.numberToArabic(feature.values_.name.timeStamp) + '</p><p style="margin-top: -11px;">' + this.trans.instant('SPEED') + ':' +
    this.commonService.numberToArabic(feature.values_.name.speed) + '</p><p style="margin-top: -11px;">' +
    this.trans.instant('LATLNG') + ':' + this.commonService.numberToArabic(feature.values_.name.latitude)
    + ',' + this.commonService.numberToArabic(feature.values_.name.longitude) +
    '</p>' + '</p><p style="margin-top: -11px;">' + this.trans.instant('LOCATION') + ':' + `${this.convertAddress}` +
    '</p>'  + '</p><p style="margin-top: -11px;">' + this.trans.instant('GPS') + ':' + this.commonService.numberToArabic(feature.values_.name.gps || '---') +
    '</p>' + '</p><p style="margin-top: -11px;">' + this.trans.instant('GSM') + ':' + this.commonService.numberToArabic(feature.values_.name.gsm || '---')  +'</p>';


  content.innerHTML += '<p  style="margin-top: -11px;">' + `${T1} ${T2} ${T3} ${T4} ` + '</p>'
  content.innerHTML += '<p style="margin-top: -11px;">' + `${BT1} ${BT2} ${BT3} ${BT4} ` + '</p>'
  content.innerHTML += '<p style="margin-top: -11px;">' + `${BH1}  ${BH2}  ` + '</p>'
  content.innerHTML += '<p style="margin-top: -11px;">' + `${BH3} ${BH4} ` + '</p>'
  container.style.visibility = "visible";
  overlay.setPosition(coordinate);
  closer.onclick = function () {
    overlay.setPosition(undefined);
    closer.blur();
    return false;
  };
}


forStartEnd(feature,evt,arg3){
   let location = arg3 == null ? feature.values_.name.address : arg3;
   const container = document.getElementById('popup');
   const content = document.getElementById('popup-content');
   const closer = document.getElementById('popup-closer');
   const overlay = new Overlay({
     element: container,
     autoPan: true,
     autoPanAnimation: {
       duration: 0
     }
   });
   this.map.addOverlay(overlay);
   const coordinate = evt.coordinate;

   if (feature.values_.name.status == 'Beginning of Trip') {
     content.innerHTML = '<h2 style="margin-bottom: -26px;border: 1px solid black;margin-top: -1px;background-color: lightgrey;text-transform: uppercase;text-align: center;">' +
     this.trans.instant('START') + '</h2><br>';
     content.innerHTML += '<p>' + this.trans.instant('TIME') + ':' + this.commonService.numberToArabic(feature.values_.name.timeStamp) + '</p><p style="margin-top: -11px;">' + this.trans.instant('SPEED') + ':' +
       this.commonService.numberToArabic(feature.values_.name.speed) + '</p><p style="margin-top: -11px;">' +
       this.trans.instant('LATLNG') + ':' + this.commonService.numberToArabic(feature.values_.name.latlng.lat)
       + ',' + this.commonService.numberToArabic(feature.values_.name.latlng.lng) +
       '</p>' + '</p><p style="margin-top: -11px;">' + this.trans.instant('LOCATION') + ':' + this.commonService.numberToArabic(location) +
       '</p>';
   }
   else if (feature.values_.name.status == 'End of Trip') {
     content.innerHTML = '<h2 style="margin-bottom: -26px;border: 1px solid black;margin-top: -1px;background-color: lightgrey;text-transform: uppercase;text-align: center;">' +
     this.trans.instant('END') + '</h2><br>';
     content.innerHTML += '<p>' + this.trans.instant('TIME') + ':' + this.commonService.numberToArabic(feature.values_.name.timeStamp) + '</p><p style="margin-top: -11px;">' + this.trans.instant('SPEED') + ':' +
       this.commonService.numberToArabic(feature.values_.name.speed) + '</p><p style="margin-top: -11px;">' +
       this.trans.instant('LATLNG') + ':' + this.commonService.numberToArabic(feature.values_.name.latlng.lat)
       + ',' + this.commonService.numberToArabic(feature.values_.name.latlng.lng) +
       '</p>' + '</p><p style="margin-top: -11px;">' + this.trans.instant('LOCATION') + ':' + this.commonService.numberToArabic(location) +
       '</p>';
   } else {
     this.markerAddress = "---";
   }
   container.style.visibility = "visible";
   overlay.setPosition(coordinate);
   closer.onclick = function () {
     overlay.setPosition(undefined);
     closer.blur();
     return false;
   }
}
  getHistory() {

    if (!this.commonService.isLoading)
      this.commonService.presentLoader();

    this.mapService.clearLayers(this.map, [1, 2])
    this.playbackUpdater.status = false;
    this.mapService.clearLayers(this.map, [1, 2]);

    var url;
    if (this.commonService.myPlatform == "desktop" && this.type != "Multi Track") {
      if (this.seperateUrlLogin == false) {
        url = serverUrl.web + '/device/trackhistory?vehicleNo=' + this.selectedVin.vin + '&fromDate=' + this.datePipe.transform(this.userSelected.fromDate, 'yyyy-MM-dd') +
          '&fromtime=' + this.datePipe.transform(this.userSelected.fromDate, 'HH:mm:ss') + '&totime=' + this.datePipe.transform(this.userSelected.toDate, 'HH:mm:ss') + '&entryPoint=' + app.entryPoint;
      }

      if (this.seperateUrlLogin == true) {
        // this.showHistoryCard = true;
        this.userSelected.fromDate = sessionStorage.getItem("fromDate")
        this.userSelected.toDate = sessionStorage.getItem("toDate")
        url = serverUrl.web + '/device/trackhistory?vehicleNo=' + this.selectedVin.vin + '&fromDate=' + this.datePipe.transform(this.userSelected.fromDate, 'yyyy-MM-dd') +
          '&fromtime=' + this.datePipe.transform(this.userSelected.fromDate, 'HH:mm:ss') + '&totime=' + this.datePipe.transform(this.userSelected.toDate, 'HH:mm:ss') + '&entryPoint=' + app.entryPoint;



      }
    } else {
      if (this.seperateUrlLogin == true) {
        this.user.fromDate = sessionStorage.getItem("fromDate")
        this.user.toDate = sessionStorage.getItem("toDate")
      }
      url = serverUrl.web + '/device/trackhistory?vehicleNo=' + this.selectedVin.vin + '&fromDate=' + this.datePipe.transform(this.user.fromDate, 'yyyy-MM-dd') +
        '&fromtime=' + this.datePipe.transform(this.user.fromDate, 'HH:mm:ss') + '&totime=' + this.datePipe.transform(this.user.toDate, 'HH:mm:ss') + '&entryPoint=' + app.entryPoint;

    }

    this.ajaxService.ajaxGet(url)
      .subscribe(res => {
        let assignedWorkZone;
        if(this.entrypoint == "WFT"){
          this.selectedVin.empDetails.workZone = "null";
           assignedWorkZone = this.selectedVin.empDetails.workZone == "null" ? [] : (this.selectedVin.empDetails.workZone).split(",")

        }
        this.trackHistoryCurrentRes = res;
        // this.trackHistoryCurrentRes = this.commonService.tempTrackRes;
    if(this.trackHistoryCurrentRes['routeGeometry'].length >0)
       if (this.trackHistoryCurrentRes.routeGeometry[0].manufacturer == "Teltonika") this.isTeltonika = true;
        
        this.commonService.presentLoader();
        if (localStorage.map != "GoogleMap") {
          this.map.on('click', async (evt) => {
            let feature: any;
            var pixel = this.map.getEventPixel(evt);
            feature = await this.map.forEachFeatureAtPixel(evt.pixel, (feature) => {
              if (feature.values_.title == "lineString") {
                return feature;
              }
            });
            if (!feature)
              feature = evt.coordinate;
            //feature = this.map.getLayers().item(1).getSource().getClosestFeatureToCoordinate(evt.coordinate);
            if(feature.hasOwnProperty("values_")){
              if (feature.values_.title == "lineString" && feature.values_.title !== "") {
                if (this.commonService.myPlatform == "desktop") {
                  if(!feature.values_.name.hasOwnProperty("status")){
                    this.showPinDetails(feature,evt,null)
                  }else{
                    this.forStartEnd(feature,evt,null)
                  }

                }else{
                  this.traveVinData['status'] = feature.values_.name.vehicleStatus;
                    this.traveVinData['latitude'] = parseFloat(feature.values_.name.latitude);
                    this.traveVinData['longitude'] = parseFloat(feature.values_.name.longitude);
                    this.traveVinData['timeStamp'] = feature.values_.name.timeStamp;
                    this.traveVinData['speed'] = feature.values_.name.speed;
                    this.traveVinData['odometer'] = feature.values_.name.odometer;
                    this.traveVinData['location'] = location
                }

            
                const regex = /[a-zA-Z]/;
                if (!regex.test(feature.values_.name.address)) {
                   let urlForGetaddrs
                    if(!feature.values_.name.hasOwnProperty("status")){
                      urlForGetaddrs = serverUrl.web + "/login/company/latlngtoaddress/" + feature.values_.name.latitude + "/" + feature.values_.name.longitude + "/" + localStorage.corpId;
                    }else{
                      urlForGetaddrs = serverUrl.web + "/login/company/latlngtoaddress/" + feature.values_.name.latlng.lat + "/" + feature.values_.name.latlng.lng + "/" + localStorage.corpId;
                    }

               this.ajaxService.ajaxGetObject(urlForGetaddrs)
                  .subscribe(res => {
                     if (this.commonService.myPlatform == "desktop") {
                      if(!feature.values_.name.hasOwnProperty("status")){
                        this.showPinDetails(feature,evt,res)
                      }else{
                        this.forStartEnd(feature,evt,res)
                      }

                    }

                
                  })
  
                }
            }
            }
            
            if (!feature) {
              this.markerInfo["show"] = false;
            }

          })
        }
        this.DateDesktop = this.datePipe.transform(this.user.fromDate, 'yyyy-MM-dd')
        this.trackHistoryData(res);
      if(this.entrypoint == "WFT"){
        assignedWorkZone.map(res => {
          if (this.geofenceCreatedList[res.split("#")[0] + "-freeForm"] && this.geofenceCreatedList[res.split("#")[0] + "-freeForm"].name == res.split("#")[1])
            this.createFreeForm({ latlng: this.geofenceCreatedList[res.split("#")[0] + "-freeForm"].latlng })
        })
      }
        setTimeout(() => this.mapService.fitBounds(this.map, 2), 500)

      })

  }
  strength(arg){
   let tempArray = []
    this.trackHistoryJson.forEach(function (
      value) {
      if (value.latitude !== 0 && value.longitude !== 0) {
        tempArray.push({
          lat: value.latitude,
          lng: value.longitude
        });
      }
    })
    let fixCount = 2;
    if(arg == "gps") {
      (!this.gpsEnable) ? this.gpsEnable = true : this.gpsEnable = false;
      (this.gpsEnable) ? this.gsmEnable = false : "";

     
    for(let i=0; i<this.trackHistoryJson.length;i++){
      if(this.trackHistoryJson[i].gps < 11 ){
        this.mapService.createLine_stop(this.map,tempArray.slice(i,(fixCount+i)),"red")
      }else if((this.trackHistoryJson[i].gps >10) && (this.trackHistoryJson[i].gps <16)){
        this.mapService.createLine_stop(this.map,tempArray.slice(i,(fixCount+i)),"orange")
      }else if((this.trackHistoryJson[i].gps >15) && (this.trackHistoryJson[i].gps <21)){
        this.mapService.createLine_stop(this.map,tempArray.slice(i,(fixCount+i)),"#faff23") 
      }else if((this.trackHistoryJson[i].gps  > 20) ){
        this.mapService.createLine_stop(this.map,tempArray.slice(i,(fixCount+i)),"#31de31")
      }
    }
    }
    else if(arg == "gsm"){
      (!this.gsmEnable) ? this.gsmEnable = true : this.gsmEnable = false;
      (this.gsmEnable) ? this.gpsEnable = false : "";

      for(let i=0; i<this.trackHistoryJson.length;i++){
        if(this.trackHistoryJson[i].gsm == 2 ){
          this.mapService.createLine_stop(this.map,tempArray.slice(i,(fixCount+i)),"red")
        }else if(this.trackHistoryJson[i].gsm == 3){
          this.mapService.createLine_stop(this.map,tempArray.slice(i,(fixCount+i)),"orange")
        }else if(this.trackHistoryJson[i].gsm == 4){
          this.mapService.createLine_stop(this.map,tempArray.slice(i,(fixCount+i)),"#faff23") //#50f254
        }else if(this.trackHistoryJson[i].gsm  == 5 ){
          this.mapService.createLine_stop(this.map,tempArray.slice(i,(fixCount+i)),"#31de31")
        }
      }
    } 
    this.checkingPlotMarkers(['stop', 'idle', 'Speed']);
    
    (this.gpsEnable || this.gsmEnable) ? document.getElementById('colorsShow').style.display='block' : document.getElementById('colorsShow').style.display='none'

    if(!this.gpsEnable && !this.gsmEnable) this.getHistory();
  }
    
    
    
  
  showLocation(data) {
    const url = serverUrl.web + "/login/company/latlngtoaddress/" + data.latitude + "/" + data.longitude + "/" + localStorage.corpId;
    this.ajaxService.ajaxGetObject(url)
      .subscribe(res => {
        if (JSON.stringify(res) == "{}") {
          data.location = data.latitude + ',' + data.longitude;
        } else {
          data.location = res;
        }

      });
  }

  trackHistoryData(data) {
    this.displayPlay = false;
    this.displayPause = true;
    this.displayStop = true;
    this.liveCount = 0;
    this.statusVehicle = false;
    this.trackHistoryJson = data.routeGeometry;
    let trackHistoryArray = [];
    let timeStamp = [];
    let maxSpeed = [];
    let odometer = [];
    let status = [];
    this.trackHistoryJson.forEach(function (
      value) {
      if (value.latitude !== 0 && value.longitude !== 0) {
        trackHistoryArray.push({
          lat: value.latitude,
          lng: value.longitude
        });
      }
      if (value.timeStamp) {
        timeStamp.push(
          `${value.timeStamp.split(" ")[1]} ${value.timeStamp.split(" ")[2]}`
        )
      }
      if (value.speed || value.speed == 0) {
        maxSpeed.push(
          value.speed
        )
      }
      if (value.odometer || value.odometer == 0) {
        odometer.push(
          parseFloat(value.odometer)
        )
      }
      if (value.vehicleStatus == "Towed") {
        status.push(value.vehicleStatus)
      }
    });
    if (status.includes('Towed')) {
      this.statusVehicle = true;
    }
    this.stopTrackHistoryArray = trackHistoryArray;
    this.timeStampArray = timeStamp;
    this.maxSpeed = maxSpeed;
    this.odometerArray = odometer;
    this.plotBackTrackingPath(trackHistoryArray, true);
  }









  plotBackTrackingPath(finalpath, check) {
    if (finalpath.length === 0) {
      if (this.type == "Multi Track")
        this.commonService.presentAlert("No data", "Data not available" + " For " + this.selectedVin.plateNo);
      else
        this.commonService.presentAlert("No data", "Data not available");

      this.mapService.clearLayers(this.map, [1, 2]);
      this.commonService.dismissLoader();
    } else {
      this.stopColor()
      this.checkingPlotMarkers(['stop', 'idle', 'Speed']);
      this.commonService.dismissLoader();

    }
  }

  checkingPlotMarkers(data) {
    for (let cnt = 0; cnt < data.length; cnt++) {
      this.plotMarkers(data[cnt]);
    }
    return;
  }



  plotMarkers(mode) {
    let end = 2;
    if (this.trackHistoryJson.length == 1) {
      end = 1
    }
    for (let cnt = 0; cnt < end; cnt++) {
      this.populateMarker(this.trackHistoryJson, cnt, mode);
      this.plotChangeMarker();
    }
  }

  plotChangeMarker() {
    let json = this.trackHistoryJson;
    var cnt: number;
    if (this.entrypoint == "ATM")
      cnt = 0
    else
      cnt = 1
    for (cnt; cnt < this.trackHistoryJson.length - 1; cnt++) {
      let source = {
        lat: parseFloat(json[cnt]['latitude']),
        lng: parseFloat(json[cnt]['longitude'])
      };
      let icon = 'assets/trackHistoryIcons/oldblue.png';

      const diffMin = this.commonService.secondstoHHMMSSCal(
        new Date(json[cnt + 1]['timeStamp']), new Date(json[cnt]['timeStamp']));
      if (json[cnt]['vehicleStatus'] == "Stop" && this.showdataMarker.stop == true) {
        icon = 'assets/trackHistoryIcons/oldred.png';
        json[cnt]["duration"] = diffMin;
        this.mapService.addMarkerWithInfoWindow(this.map, source, json[cnt], icon);
      } else if (json[cnt]['vehicleStatus'] == "Out_Of_Zone" && this.showdataMarker.outofzone == true) {
        icon = 'assets/trackHistoryIcons/oldred.png';
        json[cnt]["duration"] = diffMin;
        this.mapService.addMarkerWithInfoWindow(this.map, source, json[cnt], icon);
      } else if (json[cnt]['vehicleStatus'] == "Idle" && this.showdataMarker.idle == true) {
        icon = 'assets/trackHistoryIcons/oldblue.png';
        json[cnt]["duration"] = diffMin;
        this.mapService.addMarkerWithInfoWindow(this.map, source, json[cnt], icon);
      } else if (json[cnt]['vehicleStatus'] == "Overspeed" && this.showdataMarker.overSpeed == true) {
        icon = 'assets/trackHistoryIcons/oldorange.png';

        this.mapService.addMarkerWithInfoWindow(this.map, source, json[cnt], icon);
      } else if (json[cnt]['vehicleStatus'] == "Towed" && this.showdataMarker.towed == true) {
        icon = 'assets/trackHistoryIcons/oldrose.png';
        this.mapService.addMarkerWithInfoWindow(this.map, source, json[cnt], icon);
      } else if (json[cnt]['vehicleStatus'] == "Running" && this.showdataMarker.running == true) {
        icon = 'assets/trackHistoryIcons/oldgreen.png';
        const statusForHistory = this.commonService
          .liveTrackingStatus(this.selectedVin.vin, "Running");
        json[cnt]['vehicleStatus'] = statusForHistory;

        this.mapService.addMarkerWithInfoWindow(this.map, source, json[cnt], icon);
      } else if (json[cnt]['vehicleStatus'] == "Working" && this.showdataMarker.working == true) {
        icon = 'assets/trackHistoryIcons/oldgreen.png';
        const statusForHistory = this.commonService
          .liveTrackingStatus(this.selectedVin.vin, "Working");
        json[cnt]['vehicleStatus'] = statusForHistory;

        this.mapService.addMarkerWithInfoWindow(this.map, source, json[cnt], icon);
      } else if (json[cnt]['vehicleStatus'] == "Movement" && this.showdataMarker.movement == true) {
        icon = 'assets/trackHistoryIcons/oldgreen.png';
        const statusForHistory = this.commonService
          .liveTrackingStatus(this.selectedVin.vin, "Movement");
        json[cnt]['vehicleStatus'] = statusForHistory;

        this.mapService.addMarkerWithInfoWindow(this.map, source, json[cnt], icon);
      }
      else if (json[cnt]['vehicleStatus'] == "OnLine" && this.showdataMarker.poweron == true) {
        icon = 'assets/trackHistoryIcons/oldblue.png';
        const statusForHistory = this.commonService
          .liveTrackingStatus(this.selectedVin.vin, "OnLine");
        json[cnt]['vehicleStatus'] = statusForHistory;

        this.mapService.addMarkerWithInfoWindow(this.map, source, json[cnt], icon);
      } else if (json[cnt]['vehicleStatus'] == "PowerCut" && this.showdataMarker.powercut == true) {
        icon = 'assets/trackHistoryIcons/oldred.png';
        const statusForHistory = this.commonService
          .liveTrackingStatus(this.selectedVin.vin, "PowerCut");
        json[cnt]['vehicleStatus'] = statusForHistory;

        this.mapService.addMarkerWithInfoWindow(this.map, source, json[cnt], icon);
      } else if (json[cnt]['vehicleStatus'] == "LowBattery" && this.showdataMarker.lowbattery == true) {
        icon = 'assets/trackHistoryIcons/oldorange.png';
        const statusForHistory = this.commonService
          .liveTrackingStatus(this.selectedVin.vin, "LowBattery");
        json[cnt]['vehicleStatus'] = statusForHistory;

        this.mapService.addMarkerWithInfoWindow(this.map, source, json[cnt], icon);
      } else if (json[cnt]['vehicleStatus'] == "BatteryDrain" && this.showdataMarker.BatteryDrain == true) {
        icon = 'assets/trackHistoryIcons/oldskyblue.png';
        const statusForHistory = this.commonService
          .liveTrackingStatus(this.selectedVin.vin, "BatteryDrain");
        json[cnt]['vehicleStatus'] = statusForHistory;

        this.mapService.addMarkerWithInfoWindow(this.map, source, json[cnt], icon);
      }

    }
    this.mapService.fitBounds(this.map, 2);
    this.map.on('pointermove', (e) => {
      const pixel = this.map.getEventPixel(e.originalEvent);
      const hit = this.map.hasFeatureAtPixel(pixel);
      document.getElementById('map').style.cursor = hit ? 'pointer' : '';
    });
  }
  populateMarker(json, cnt, mode) {
    let source = {
      lat: parseFloat(json[cnt]['latitude']),
      lng: parseFloat(json[cnt]['longitude'])
    };
    const currSpeed = parseInt(json[cnt]['speed']);
    if (cnt === 0) {
      // const starticon = 'assets/vtstrackhisIcon/startFlag.png';
      const starticon = this.trackHistoryJson.length == 1 ? 'assets/vtstrackhisIcon/endFlag.png' : 'assets/vtstrackhisIcon/startFlag.png';
 this.createMarkerforStopIdle(source, starticon, '', 'Beginning of Trip',json[cnt]['timeStamp'], currSpeed,json[cnt]['address']);
    }
    if (cnt == 1) {
      cnt = this.trackHistoryJson.length - 1;
      source = {
        lat: parseFloat(json[cnt]['latitude']),
        lng: parseFloat(json[cnt]['longitude'])
      };
      const endicon = 'assets/vtstrackhisIcon/endFlag.png';
      this.createMarkerforStopIdle(source, endicon, '','End of Trip', json[cnt]['timeStamp'],currSpeed,json[cnt]['address']);
      this.mapService.fitBounds(this.map, 1);
    }
    // if( this.showdataMarker.powercut){
    //   const endicon = 'assets/trackHistoryIcons/oldred.png';
    //   this.createMarkerforStopIdle(source, endicon, '',
    //   'End of Trip', json[cnt]['timeStamp'],
    //   currSpeed);
    // }
    this.map.on('pointermove', (e) => {
      const pixel = this.map.getEventPixel(e.originalEvent);
      const hit = this.map.hasFeatureAtPixel(pixel);
      document.getElementById('map').style.cursor = hit ? 'pointer' : '';
    });

  }

  createMarkerforStopIdle(source, icon, diffrenceinMin, status, time, speed,addrs) {
    let min = '', speedCheck = '';
    let contentString: object = {};
    contentString["status"] = status;
    if (status === 'Stop') {
      contentString["duration"] = diffrenceinMin;
    } else if (status === 'Idle') {
      contentString["duration"] = diffrenceinMin;
    } else {
      contentString["speed"] = speed;
    }
    contentString["latlng"] = source;
    contentString["timeStamp"] = time;
    contentString["address"] = addrs;
    const dateTime = time.split(' ')[0] + ' ' + time.split(' ')[1];
    const amPm = time.split(' ')[2];
    this.mapService.addMarkerWithInfoWindow(this.map, source, contentString, icon);
  }

  stopMapToDefaultView() {
    this.playbackUpdater.status = false;
    this.trackHistoryData(this.trackHistoryCurrentRes);
  }
  getLiveTrackIcon = (color, path) => {
    if(this.traveVinData.icon =="REFRIGERATOR"){
      var viewbox="-220 -1000 2471.4 2448.1";
      }
    else{
    var viewbox="-853 -1600 2471.4 2458.1";  
    }
    let svg='<svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" width="24.714mm" height="24.481mm" version="1.1" style="shape-rendering:geometricPrecision; text-rendering:geometricPrecision; image-rendering:optimizeQuality; fill-rule:evenodd; clip-rule:evenodd"  ' +
    '   viewBox="' 
    +viewbox+
    '"    xmlns:xlink="http://www.w3.org/1999/xlink"> <path class="fil0" d="'
  + path
  + '" fill="'
  + color
  + '"/> </svg>';
    const src = 'data:image/svg+xml;charset=utf-8,'
      + encodeURIComponent(svg);
     return src;
  }

  async moveVehicleInMap(action, type) {
    if (this.commonService.myPlatform != "desktop") {
      if (this.seperateUrlLogin) {
        this.seprateCard('block')
      }
    }
    let assignedWorkZone
    if(this.entrypoint == "WFT"){
      this.selectedVin.empDetails.workZone = "null";
       assignedWorkZone = this.selectedVin.empDetails.workZone == "null" ? [] : (this.selectedVin.empDetails.workZone).split(",");
    }


    if (this.trackHistoryJson) {
      if (type == "default") {
        (this.display == "play") ? this.display = 'pause' : this.display = 'play';
        (this.display == "play") ? this.show = true : this.show = false;
      }
      if (type == "trigger") {
        clearInterval(this.interval);
      }
      if (action == "play") {
        this.playbackUpdater.status = true;
      }
      //this.playbackIcon = 'assets/vtsicon/CAR/Running.svg';
      
        var icon=this.commonService.vehicleModelmap[this.traveVinData.icon]
      
      
      this.playbackIcon=this.getLiveTrackIcon(this.commonService.color["Running"],icon)
  

      if (app.entryPoint == "WFT") {
        this.playbackIcon = 'assets/vtsicon/employee.svg';
      }
      if (this.trackHistoryJson.length) {
        if (this.liveCount == 0) {
          this.mapService.clearLayers(this.map, [1, 2])
          var latitude = parseFloat(this.trackHistoryJson[0]['latitude']);
          var longitude = parseFloat(this.trackHistoryJson[0]['longitude']);
          var len = this.trackHistoryJson.length - 1;
          this.mapService.addMarker(this.map, { lat: latitude, lng: longitude }, 'playback', this.playbackIcon);
        }
        this.gpsEnable = false;
        this.gsmEnable = false;
        if (action == 'play') {
          this.displayPlay = true;
          this.displayStop = false;
          this.displayPause = false;
          this.playback = this.startInterval(this.playBackSpeed);
          await this.intervalLoop();
        } else if (action == 'pause') {
          this.displayPlay = false;
          this.displayPause = true;
          this.displayStop = false;
          clearInterval(this.interval);
        } else {
          this.displayPlay = false;
          this.displayPause = true;
          this.displayStop = true;
          this.liveCount = 0;
          this.mapService.removeMarkersById(this.map, 'playback');

          this.show = true
          clearInterval(this.interval);
          this.display = 'play'
          this.playbackUpdater.status = false;
          this.trackHistoryData(this.trackHistoryCurrentRes);
        }
      }
    } else {
      // this.commonService.presentToast("No tracks to play");
    }
    if(this.entrypoint == "WFT"){
      assignedWorkZone.map(res => {
        if (this.geofenceCreatedList[res.split("#")[0] + "-freeForm"] && this.geofenceCreatedList[res.split("#")[0] + "-freeForm"].name == res.split("#")[1])
          this.createFreeForm({ latlng: this.geofenceCreatedList[res.split("#")[0] + "-freeForm"].latlng })
      })

    }
    this.mapService.fitBounds(this.map, 2);
  }
  datas;

  async intervalLoop() {
    while (this.liveCount < this.trackHistoryJson.length) {
      var latitude = parseFloat(this.trackHistoryJson[this.liveCount]['latitude']);
      var longitude = parseFloat(this.trackHistoryJson[this.liveCount]['longitude']);
      if (this.trackHistoryJson[this.liveCount]['vehicleStatus'] != "") {
        this.traveVinData['status'] = this.trackHistoryJson[this.liveCount]['vehicleStatus'];
      } else if (this.trackHistoryJson[this.liveCount]['vehicleStatus'] == "") {
        this.traveVinData['status'] = "Running";
      }

      this.traveVinData['latitude'] = parseFloat(this.trackHistoryJson[this.liveCount]['latitude']);
      this.traveVinData['longitude'] = parseFloat(this.trackHistoryJson[this.liveCount]['longitude']);
      this.traveVinData['timeStamp'] = this.trackHistoryJson[this.liveCount].timeStamp.split(" ")[0] + " " + this.trackHistoryJson[this.liveCount].timeStamp.split(" ")[1];
      this.traveVinData['speed'] = this.trackHistoryJson[this.liveCount]['speed'];
      this.traveVinData['location'] = this.trackHistoryJson[this.liveCount]['address'];
      this.traveVinData['odometer'] = "";
      this.datas = "world"
      this.playbackUpdater["timeStamp"] = this.trackHistoryJson[this.liveCount].timeStamp.split(" ")[0] + " " + this.trackHistoryJson[this.liveCount].timeStamp.split(" ")[1];
      this.playbackUpdater["speed"] = this.trackHistoryJson[this.liveCount]['speed'];
      if (this.playBackOdometer == 0) {
        this.playBackOdometer = this.trackHistoryJson[this.liveCount]["odometer"] / 1000;
      } else {
        this.playBackOdometer = this.playBackOdometer + (this.trackHistoryJson[this.liveCount]["odometer"] / 1000);
      }

      this.playbackUpdater["odometer"] = this.playBackOdometer.toFixed(3);
      let lineArray = new Array(2);
      if (this.liveCount > 0) {
        let prevLatLng = {
          "lat": parseFloat(this.trackHistoryJson[this.liveCount - 1]['latitude']),
          "lng": parseFloat(this.trackHistoryJson[this.liveCount - 1]['longitude'])
        }
        let currLatLng = {
          "lat": latitude,
          "lng": longitude
        }
        lineArray = [prevLatLng, currLatLng];
        if (this.trackHistoryJson[this.liveCount]['speed'] != "0") {
          this.mapService.moveMarker(this.map, 'playback', longitude, latitude, this.playbackIcon, '', lineArray);
          this.liveCount++;
          break;
        } else if (this.liveCount == this.trackHistoryJson.length - 1) {
          this.mapService.moveMarker(this.map, 'playback', longitude, latitude, this.playbackIcon, '', lineArray);
        } else {
          this.mapService.moveMarker(this.map, 'playback', longitude, latitude, this.playbackIcon, '', lineArray);

        }
        this.liveCount++;
      } else {
        this.liveCount++;
      }

    }
    if (this.liveCount == this.trackHistoryJson.length) {
      this.moveVehicleInMap('pause', 'default')
      this.liveCount = 0;
      this.show = true;
      clearInterval(this.interval);
    }
  }
  seprateCard(ar) {
    if (this.seperateUrlLogin) {
      document.getElementById("septCard").style.display = ar;
    }

  }

  ngOnDestroy() {
    clearInterval(this.interval);
  }



  async delay(milliseconds: number) {
    return new Promise<void>(resolve => {
      setTimeout(resolve, milliseconds);
    });
  }

  async startInterval(milliseconds: number) {
    return new Promise<void>(resolve => {
      this.interval = setInterval(async () => {
        await this.intervalLoop();
      }, milliseconds);
    });
  }
  playbackSpeedTrigger(speed) {
    if (speed >= 0 && speed <= 125) {
      this.playBackSpeed = 1000;
    } else if (speed >= 125 && speed <= 250) {
      this.playBackSpeed = 500;
    } else if (speed >= 250 && speed <= 500) {
      this.playBackSpeed = 125;
    } else if (speed >= 500 && speed <= 1000) {
      this.playBackSpeed = 65;
    }

    if (this.display == "pause")
      this.moveVehicleInMap("play", "trigger");
  }


  ngOnChanges() {

    if (this.gridLiveChange) {
      if (this.type == "Multi Track")
        this.selectedVin = this.gridLiveChange;
      else
        this.selectedVin = JSON.parse(localStorage.selectedVin);
      this.traveVinData = this.selectedVin;
      if (localStorage.pageSelector != "Multi Track")
        this.ngOnInit();
    }
    if (this.seperateUrlLogin == true) {
      if (this.type == "Multi Track")
        this.selectedVin = this.gridLiveChange;
      else
        this.selectedVin = JSON.parse(localStorage.selectedVin);
      this.traveVinData = this.selectedVin;
    }

  }
  showRange() {
    this.showHideRangeArrow = !this.showHideRangeArrow
  }
  showFenceInMap() {
    this.geofenceCreatedList = [];
    let url = serverUrl.web + "/Alert/GeozoneDatas?data=" + localStorage.corpId;
    this.ajaxService.ajaxGetJson(url).subscribe((res) => {
      this.geofenceCreatedList = res.Freeform;
    });

  }

  changeArView(data) {
    if (app.entryPoint == 'WFT')
      return 'arabicPlateNo'
    return data
  }

  splitNameArabic(data) {
    if (localStorage.seprateLogin !== 'true')
      return this.commonService.splitNameArabic(data, this.directory);
  }

  alertAPI() {
    this.commonService.presentLoader();
    let currentDate = new Date();
    let currentTime = currentDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', second: '2-digit' });
    if (this.commonService.myPlatform != 'desktop') {
      let fromDate = this.datePipe.transform(this.user.fromDate, 'yyyy-MM-dd') + " " + this.datePipe.transform(this.user.fromDate, 'HH:mm:ss');
      this.replacedfromDate = fromDate.toString();
      let toDate = this.user.toDate;
      // this.replacedtoDate = toDate.split("T").join(" ");
      this.replacedtoDate = this.datePipe.transform(this.user.fromDate, 'yyyy-MM-dd') + " " + this.datePipe.transform(this.user.toDate, 'HH:mm:ss');
      var data = {
        "address": "false",
        "vin": this.selectedVin.vin,
        "userId": localStorage.userName,
        "companyId": localStorage.corpId,
        "fromDate": this.replacedfromDate,
        "toDate": this.replacedtoDate,
        "currentTime": currentTime
      };

    }
    else {
      var data = {
        "address": "false",
        "vin": this.selectedVin.vin,
        "userId": localStorage.userName,
        "companyId": localStorage.corpId,
        "fromDate": this.userSelected.fromDate,
        "toDate": this.userSelected.toDate,
        "currentTime": currentTime
      };
    }
    if (((this.userSelected.fromDate && this.userSelected.toDate) || (this.replacedfromDate && this.replacedtoDate)) && (this.vins || this.selectedVin.vin)) {
      const url = serverUrl.web + "/alert/mobileAlert";
      this.ajaxService.ajaxPostWithBody(url, data)
        .subscribe(res => {
          if (res.length == 0) {
            this.closeDiv("track-Alert");
            this.commonService.presentAlert(this.trans.instant("Warning"), this.trans.instant("Alerts not found"));
            this.show = true;

          } else {
            this.allAlts = res;
          }
          this.commonService.dismissLoader();

        })
    }
  }
  close() {
    this.showHistoryCard = true;
  }
  trackAlert() {
    this.trackAlertDis = "block";
    this.show = false;
    this.alertAPI();
  }
  openDiv() {
    this.trackIdDis = 'block';
    this.showHistoryCard = true;
  }
  trackDetail() {
    this.trackDetailAPI();
    this.show = false;
    this.closeDiv("track-Alert");
  }
  trackDetailAPI() {
    this.commonService.presentLoader();
    if (this.userSelected.fromDate && this.commonService.myPlatform == "desktop") {
      var fromDate: any = this.userSelected.fromDate.split(" ")[0];
      var startTime: any = this.userSelected.fromDate.split(' ')[1];
      var endTime: any = this.userSelected.toDate.split(' ')[1];
    }

    else if (this.commonService.myPlatform != "desktop") {
      var fromDate: any = this.datePipe.transform(this.user.fromDate, 'yyyy-MM-dd');
      var startTime: any = this.datePipe.transform(this.user.fromDate, 'HH:mm:ss');
      var endTime: any = this.datePipe.transform(this.user.toDate, 'HH:mm:ss')
    }
    else {
      var fromDate: any = this.user.fromDate.split('T')[0];
      var startTime: any = this.user.fromDate.split('T')[1];
      var endTime: any = this.user.toDate.split('T')[1];
    }

    const url = `${serverUrl.web}/report/trackReport?vehicleNo=${this.selectedVin.vin}&fromDate=${fromDate}&fromtime=${startTime}&totime=${endTime}`;
    this.ajaxService.ajaxGet(url)
      .subscribe(res => {
        if (res.trackHistory.length == 0) {
          this.commonService.dismissLoader();
          this.closeDiv("track-id");
          this.commonService.presentAlert(this.trans.instant("Warning"), this.trans.instant("No Data"));

        }
        else {
          this.commonService.dismissLoader();
          this.showData = res;
          this.openDiv();
          this.closeDiv("track-id");
          this.openDiv();
        }
      }, error => {
        console.error('Error fetching track data:', error);
      });

  }
  closeDiv(ev) {
    if (ev == "track-id") { this.trackIdDis = 'none'; this.show = true; }
    else if (ev == "track-Alert") { this.trackAlertDis = "none" }
    if (ev == "track-id") { this.showHistoryCard = false };
    this.showHistoryCard = false;
  }
  saveURL(tok) {
    let require;
    if (this.multiURL) {
      if (this.commonService.myPlatform == "desktop") {
        require = this.selectedVin.vin + "/" + this.dateTime + "/" + app.entryPoint + "/" + localStorage.corpId + "/" + tok + "/" + this.colorTrack;
      } else {
        require = this.selectedVin.vin + "/" + this.mobileDate + "/" + app.entryPoint + "/" + localStorage.corpId + "/" + tok + "/" + this.colorTrack;;
      }
    }
    else {
      if (this.commonService.myPlatform == "desktop") {
        require = this.selectedVin.vin + "/" + this.userSelected.fromDate.replace(" ", "T") + "#" + this.userSelected.toDate.replace(" ", "T") + "/" + app.entryPoint + "/" + localStorage.corpId + "/" + tok;
      } else {
        // require = this.selectedVin.vin + "/" + this.user.fromDate.replace(" ", "T") + "#" + this.user.toDate.replace(" ", "T") + "/" + app.entryPoint + "/" + localStorage.corpId + "/" + tok;
        require = this.selectedVin.vin + "/" + this.datePipe.transform(this.user.fromDate, 'yyyy-MM-dd') + "T" + this.datePipe.transform(this.user.fromDate, 'HH:mm:ss') + "#" + this.datePipe.transform(this.user.fromDate, 'yyyy-MM-dd') + "T" + this.datePipe.transform(this.user.toDate, 'HH:mm:ss') + "/" + app.entryPoint + "/" + localStorage.corpId + "/" + tok;
      }
    }
    let fullUrl = document.location.href + "/" + require
    //  let fullUrl = "https://arabiandynamics.net" + "/" + require
    const url = serverUrl.web + '/device/trackApigenerate';
    let data = {
      "fullURL": fullUrl,
    }
    this.ajaxService.ajaxPostMethod(url, data)
      .subscribe(res => {
        let final = document.location.href.split("/#/")[0] + "/#/$tracking$/" + res.shortURL;
        // let final = "https://arabiandynamics.net" + "/#/$tracking$/" + res.shortURL;
       
        if (this.commonService.myPlatform == "desktop") {
          this.copy(final);
        } else {
          this.copyToMobileClipboard(final)
        }

      })
  }
  copyToMobileClipboard(text: string) {
    this.clipboard.copy(text)
      .then(() => {
        console.log('Text copied to mobile clipboard');
      })
      .catch((error) => {
        console.error('Error copying text to mobile clipboard:', error);
      });
    this.commonService.presentToast("Url copied.");
    this.commonService.dismissLoader();
  }
  copy(arg) {
    navigator.clipboard.writeText(arg).then(function () {
      console.log('Copied! uniqe', arg);
    }, function () {
      console.log('Copy error')
      this.commonService.dismissLoader();
      this.commonService.presentToast("Url not generated.");
      return null;
    });
    this.commonService.presentToast("Url copied.");
    this.commonService.dismissLoader();
  }

  mainTrack() {
    this.multiURL = false;
    this.token();
  }


  token() {
    if (this.trackHistoryJson.length == 0) {
      this.urlpresentAlert = true;
      this.multiURL = false;
    } else {
      this.urlpresentAlert = false;
    }
    if (this.urlpresentAlert) {
      if (this.type == "Multi Track")
        this.commonService.presentAlert("No data", "Data not available" + " For " + this.selectedVin.plateNo);
      else
        this.commonService.presentAlert("No data", "Data not available");
      return null;
    }
    this.commonService.presentLoader();
    const url = serverUrl.web + '/authenticatebyuser';
    let data = {
      "companyname": localStorage.corpId,
      "username": localStorage.userName,
      "password": localStorage.password
    }
    this.ajaxService.ajaxPostMethod(url, data)
      .subscribe(res => {
        this.saveURL(res.token);
      })
  }
  radioGroupChange(event) {
    this.radio = event.target.value

    if (this.radio == "callsign") {
      this.vehoerationcode = Object.values(this.dashboardJson);
      this.vehoerationcodesourcs=[];
      this.vehoerationcode.map((res) => {
        if (res.assetName != "")
          this.vehoerationcodesourcs.push(res)
      })
      var source = {
        datatype: 'json',
        datafields: [{ name: 'assetName' }, { name: 'vin' }],
        id: 'id',
        localdata: this.vehoerationcodesourcs
      }
      if (this.vehoerationcodesourcs.length != 0) {
        this.plateNoSelected = this.vehoerationcodesourcs[0].plateNo
        this.selectedVin = this.vehoerationcodesourcs[0];
        this.traveVinData = this.vehoerationcodesourcs[0];
        setTimeout(() => {
          this.plateNoComobox.selectItem(this.vehoerationcodesourcs[0].vin)
        }, 500)
      }
    } else if (this.radio == "plate") {
      this.plateNoList = Object.values(this.dashboardJson);
      this.plateNoList.map((res, index) => {
        this.plateNoList[index]['arabicPlateNo'] = this.plateNoList[index].plateNo.split("@,@").length >= 2 ? this.plateNoList[index].plateNo.split("@,@")[0] + ` (${this.plateNoList[index].plateNo.split("@,@")[1]})` : this.plateNoList[index].plateNo.split("@,@")[0]
      })
      var source = {
        datatype: 'json',
        datafields: [{ name: 'plateNo' }, { name: 'arabicPlateNo' }, { name: 'vin' }],
        id: 'id',
        localdata: this.plateNoList
      }
      this.plateNoSelected = this.plateNoList[0].plateNo
      this.selectedVin = this.plateNoList[0];
      this.traveVinData = this.plateNoList[0];
      setTimeout(() => {
        this.plateNoComobox.selectItem(this.plateNoList[0].vin)
      }, 500)
    }
    this.plateNos = new jqx.dataAdapter(source);

  }
  ngOnInit() {
    this.callsign = localStorage.callsign
    this.route.queryParams.subscribe(params => {
      if (params['type'] == undefined) {
        this.pageSelector = this.type
      } else {
        this.pageSelector = params['type']
      }
    });
    if (document.location.href.split('#')[1] == '/tabs/gridview/All' || this.type == "Multi Track" || this.type =="Track") {
      this.Gridview = true;
      this.Gridview1=true
    } else {
      this.Gridview = false;
      this.Gridview1=false;
    }

    if (sessionStorage.seprateLogin == 'true') {
      this.seperateUrlLogin = true;

    } else {
      this.seperateUrlLogin = false;
    }
    this.entrypoint = app.entryPoint



    if (this.commonService.myPlatform == 'desktop') {
      this.platWidth = "23%"
      this.platMargin = "3.5%";
    } else {
      this.platWidth = "100%";
      this.platMargin = "18%";
    }

    this.entrypoint = app.entryPoint
    // coomon end

    if (this.seperateUrlLogin == false) {
      let a: any[] = Object.values(storageVariable.dashboardData.liveDatas);
      for (let i = 0; i < a.length; i++) {
        if (!a[i]['warrantyExpiry'])
          this.dashboardJson.push(a[i]);
      }

      const url1 = serverUrl.web + '/login/getPreferences?key=VehOperCodeFilter&companyId=' + localStorage.corpId;
            this.ajaxService.ajaxGetWithString(url1)
              .subscribe(res => {
                this.callsignfilter=res
              })

      if (this.router.url == "/trackhistory" && this.commonService.myPlatform == "desktop") {
        this.showPlateNo = false;
        this.plateNoList = Object.values(this.dashboardJson);
        this.plateNoList.map((res, index) => {
          this.plateNoList[index]['arabicPlateNo'] = this.plateNoList[index].plateNo.split("@,@").length >= 2 ? this.plateNoList[index].plateNo.split("@,@")[0] + ` (${this.plateNoList[index].plateNo.split("@,@")[1]})` : this.plateNoList[index].plateNo.split("@,@")[0]
        })
        this.vehoerationcode = Object.values(this.dashboardJson);
        this.vehoerationcode.map((res) => {
          if (res.assetName != "")
            this.vehoerationcodesourcs.push(res)
        })
        if (this.radio == "callsign") {
          var source = {
            datatype: 'json',
            datafields: [{ name: 'assetName' }, { name: 'vin' }],
            id: 'id',
            localdata: this.vehoerationcodesourcs
          }
        } else {
          var source = {
            datatype: 'json',
            datafields: [{ name: 'plateNo' }, { name: 'arabicPlateNo' }, { name: 'vin' }],
            id: 'id',
            localdata: this.plateNoList
          }
        }
        this.plateNos = new jqx.dataAdapter(source);
        this.plateNoSelected = this.plateNoList[0].plateNo
        this.selectedVin = this.plateNoList[0];
        this.traveVinData = this.plateNoList[0];
        setTimeout(() => {
          this.plateNoComobox.selectItem(this.plateNoList[0].vin)
         }, 500)

      } else {
        if (localStorage.selectedVin) {
          if (this.type == "Multi Track")
            this.selectedVin = this.gridLiveChange;
          else
            this.selectedVin = JSON.parse(localStorage.selectedVin);
          this.traveVinData = this.selectedVin;
        }
      }
      this.trackModal('today');
      this.createMap();

    } else if (this.seperateUrlLogin == true) {
      if (this.type == "Multi Track")
        this.selectedVin = this.gridLiveChange;
      else
        this.selectedVin = JSON.parse(localStorage.selectedVin);
      this.traveVinData = this.selectedVin;
      this.datas = "hello"
      setTimeout(() => {
        this.map = this.mapService.loadMap(this.mapElement.nativeElement, { lat: this.traveVinData.latitude, lng: this.traveVinData.longitude }, false, false);
        this.mapService.setCenter(this.map, { lat: 17.786351, lng: 78.090820 });
        this.getHistory()
      }, 1000);
      this.user = {
        "fromDate": localStorage.trackDate.split("#")[0].replace("%20", "T"),
        "toDate": localStorage.trackDate.split("#")[1].replace("%20", "T"),
      }

    }

    // this.traveVinData['type'] = "trackhistory";
    this.commonService.updateLanguageDir
      .subscribe(res => {
        this.directory = res == "" ? this.directory : res
      })
    this.showFenceInMap()
  }
  toggle() {
    this.hide = !this.hide
  }


}


