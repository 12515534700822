import { Component, OnInit, ViewChild, Input, ChangeDetectorRef } from "@angular/core";
import { IonSlide, IonSlides } from "@ionic/angular";
import { jqxTreeComponent } from "jqwidgets-ng/jqxtree";
import { AjaxService } from "src/app/services/ajax.service";
import { AuthMapService } from "src/app/services/auth-map.service";
import { GoogleMapService } from "src/app/services/google-map.service";
import { OpenlayerMapService } from "src/app/services/openlayer-map.service";
import { CommonService } from "src/app/services/common.service";
import { app, serverUrl, storageVariable,languageInitializer } from "src/environments/environment";
import { ModalController, AlertController } from "@ionic/angular";
import { TranslateService } from "@ngx-translate/core";
import { TranslateConfigService } from "../../services/translate-config.service";

@Component({
  selector: "app-geofence-association-model",
  templateUrl: "./geofence-association-model.component.html",
  styleUrls: ["./geofence-association-model.component.scss"],
})
export class GeofenceAssociationModelComponent implements OnInit {
  dataAdapter: any;
  records: any;
  Search: any;
  geofenceGridForm = [];
  checkentryval1: any;
  mailaddress: any;
  checkentryval: any;
  preexpdate: any;
  newTrustFormVisible1: any = true;
  selectedDays: number;
  selectedDay = [];
  days: any;
  validfor: any = "0";
  checkStatus: any = "";
  checkonleave: any = "";
  checkonenter: any = "";
  checksendbetwn: any = "";
  validfr: any;
  mobilenumber: any;
  betweenvalues: any;
  dayvalues: any = [];
  selecteddayvalues: any;
  checksearch: any;
  selectedValue = [];
  map;
  @Input() mode;
  geofence: any;
  geoJsonId: any;
  entryPoint = app.entryPoint
  layerModal = false;
  directory: string = languageInitializer.directory;
  constructor(
    public ajaxService: AjaxService,
    public mapService: AuthMapService,
    public gMapService: GoogleMapService,
    public olMapService: OpenlayerMapService,
    private commonService: CommonService,
    private modalController: ModalController,
    private alertController: AlertController,
    public translateConfigService: TranslateConfigService,
    public trans: TranslateService,
    private cdRef: ChangeDetectorRef
  ) {
    this.mapService = new OpenlayerMapService();
  }
  @ViewChild("myTree", { static: false }) myTree: jqxTreeComponent;
  @ViewChild("geofenceTree", { static: false }) geofenceTree: jqxTreeComponent;
  @ViewChild("geoSlide", { static: false }) geoSlide: IonSlides;
  @ViewChild("mapElement", { static: false }) mapElement;
  @Input() selectedRow;
  @Input() multiRow;
  treeStructureObject: any = {};
  treeGeofenceObject: any = {};
  dashboardJson: any[] = storageVariable.groupAndVin;
  geofenceList = {};
  filterData: any = {};
  treeStructureJson = [];

  source = {
    datatype: "json",
    datafields: [
      { name: "id" },
      { name: "parentid" },
      { name: "text" },
      { name: "value" },
    ],
    id: "id",
    localdata: this.treeStructureJson,
  };

  switchMap() {
    this.layerModal = !this.layerModal
  this.map.getLayers().item(3).setVisible(this.layerModal);
    this.map.getLayers().item(0).setVisible(!this.layerModal);
  }
  ngAfterViewInit(): void {
    this.myTree.expandAll();

    if (this.mode == "add") {
      this.betweenvalues = "Except 12:00 AM to 06:00 AM";
      this.validfr = "0";
      let days = 127;
      this.days = days;
      if (days / 64 >= 1) {
        days = days % 64;
        this.dayvalues.push("64");
      }
      if (days / 32 >= 1) {
        days = days % 32;
        this.dayvalues.push("32");
      }
      if (days / 16 >= 1) {
        days = days % 16;
        this.dayvalues.push("16");
      }
      if (days / 8 >= 1) {
        days = days % 8;
        this.dayvalues.push("8");
      }
      if (days / 4 >= 1) {
        days = days % 4;
        this.dayvalues.push("4");
      }
      if (days / 2 >= 1) {
        days = days % 2;
        this.dayvalues.push("2");
      }
      if (days == 1) this.dayvalues.push("1");
    }

    if (this.mode == "edit" || this.mode == "multi") {
      this.selectedRow = this.multiRow[0];
      this.newTrustFormVisible1 = false;
      for (var n in this.multiRow) {
        let selectRow = document.getElementById(
          this.treeStructureObject[this.multiRow[n].plateNo].id
        );
        this.myTree.checkItem(selectRow, true);
      }
      this.myTree.disabled(true);

      if (this.mode == "edit") {
        let smscheck =
          this.selectedRow.smsThrough == "true" ||
            this.selectedRow.smsThrough1 == "true"
            ? true
            : false;
        let emailcheck =
          this.selectedRow.throughEmail == "true" ||
            this.selectedRow.throughEmail1 == "true"
            ? true
            : false;

        if (smscheck) {
          if (this.selectedRow.mobileNumber != "")
            this.mobilenumber = this.selectedRow.mobileNumber;

          if (this.selectedRow.mobileNumber1 != "")
            this.mobilenumber =
              this.mobilenumber + "," + this.selectedRow.mobileNumber1;

          this.checkentryval = "Sms";
        }
        if (emailcheck) {
          if (this.selectedRow.emailAddress != "")
            this.mailaddress = this.selectedRow.emailAddress;

          if (this.selectedRow.emailAddress != "")
            this.mailaddress =
              this.mailaddress + "," + this.selectedRow.emailAddress1;

          this.checkentryval1 = "Mail";
        }
        this.notificationType = [
          { val: "Push", isChecked: true },
          { val: "Sms", isChecked: smscheck },
          { val: "Mail", isChecked: emailcheck },
        ];

        this.AlertTypes = [
          { val: "Status", isChecked: this.selectedRow.status },
          { val: "On Leave", isChecked: this.selectedRow.onLeave },
          { val: "On Enter", isChecked: this.selectedRow.onEnter },
        ];

        let days = this.selectedRow.alertDay;
        if (days / 64 >= 1) {
          days = days % 64;
          this.dayvalues.push("64");
        }
        if (days / 32 >= 1) {
          days = days % 32;
          this.dayvalues.push("32");
        }
        if (days / 16 >= 1) {
          days = days % 16;
          this.dayvalues.push("16");
        }
        if (days / 8 >= 1) {
          days = days % 8;
          this.dayvalues.push("8");
        }
        if (days / 4 >= 1) {
          days = days % 4;
          this.dayvalues.push("4");
        }
        if (days / 2 >= 1) {
          days = days % 2;
          this.dayvalues.push("2");
        }
        if (days == 1) this.dayvalues.push("1");

        if (this.selectedRow.alertTime == "06:01_23:59")
          this.betweenvalues = "Except 12:00 AM to 06:00 AM";
        else this.betweenvalues = "Any Time";

        if (this.selectedRow.validityExp != "")
          this.preexpdate = this.selectedRow.validityExp;
        else this.newTrustFormVisible1 = true;
      }
      if (this.mode == "multi") {
        this.newTrustFormVisible1 = true;
        this.checkStatus = "";
        this.checkonleave = "";
        this.checkonenter = "";
        this.selectedDays = 0;
        this.mobilenumber = "";
        this.validfor = "";
        this.checksendbetwn = "";
        this.mailaddress = "";
      }
    }
    this.cdRef.detectChanges();

  }

  notificationType = [
    { val: "Push", isChecked: true },
    { val: "Sms", isChecked: false },
    { val: "Mail", isChecked: false },
  ];

  AlertTypes = [
    { val: "Status", isChecked: false },
    { val: "On Leave", isChecked: false },
    { val: "On Enter", isChecked: false },
  ];

  changenotificationmode(event) {
    if (event.val == "Sms" && event.isChecked) this.checkentryval = event.val;

    if (event.val == "Sms" && event.isChecked == false) this.checkentryval = "";

    if (event.val == "Mail" && event.isChecked) this.checkentryval1 = event.val;

    if (event.val == "Mail" && event.isChecked == false)
      this.checkentryval1 = "";
  }

  onChangedays($event) {
    this.selectedDay = $event.target.value;
    if (this.selectedDay == undefined) this.selectedDay = $event;

    for (var i in this.selectedDay) {
      if (i == "0") this.days = Number(this.selectedDay[i]);
      else this.days = this.days + Number(this.selectedDay[i]);
    }
    this.selectedDays = this.days;
    this.selectedValue.push('dayvalues')
  }

  onChangesendbetwn($event) {
    this.checksendbetwn = $event.target.value;
    this.selectedValue.push('betweenvalues')
  }

  onChangevalidfor($event) {
    this.validfor = $event.target.value;
    this.selectedValue.push('validfr')
  }

  changeAlertmode(event) {
    if (event.val == "Status" && event.isChecked)
      this.checkStatus = "true";

    if (event.val == "Status" && event.isChecked == false)
      this.checkStatus = "false";

    if (event.val == "On Leave" && event.isChecked)
      this.checkonleave = "true";

    if (event.val == "On Leave" && event.isChecked == false)
      this.checkonleave = "false";

    if (event.val == "On Enter" && event.isChecked)
      this.checkonenter = "true";

    if (event.val == "On Enter" && event.isChecked == false)
      this.checkonenter = "false";

  }
  nextSlide() {
    this.geoSlide.slideNext(500).then((res) => { });
  }

  async associationGeofence() {
    this.associationmultiGeofence("");
  }
  radioGroupChange($event) {
    this.checksearch = $event.target.value;
    if (this.checksearch == undefined) {
      this.checksearch = "plateNo";
    }
  }
  // searchgroup() {
  //   var foundItem = 0;
  //   //  this.checksearch = "group"
  //   if (this.checksearch == "plateNo") {
  //     var items = this.myTree.getItems();
  //     var searchedValue = this.Search.toLowerCase();
  //     for (var i = 0; i < items.length; i++) {
  //       if (items[i].label.toLowerCase().indexOf(searchedValue) == 0) {
  //         this.myTree.expandItem(items[i].parentElement);
  //         this.myTree.selectItem(items[i]);
  //         if (foundItem != i) {
  //           this.myTree.collapseAll();
  //         }
  //         this.myTree.expandItem(items[i].parentElement);
  //         foundItem = i;
  //         break;
  //       }
  //     }
  //   } else {
  //     var items = this.geofenceTree.getItems();
  //     var searchedValue = this.Search.toLowerCase();
  //     for (var i = 0; i < items.length; i++) {
  //       if (items[i].label.toLowerCase().indexOf(searchedValue) == 0) {
  //         this.geofenceTree.expandItem(items[i].parentElement);
  //         this.geofenceTree.selectItem(items[i]);
  //         if (foundItem != i) {
  //           this.geofenceTree.collapseAll();
  //         }
  //         this.geofenceTree.expandItem(items[i].parentElement);
  //         foundItem = i;
  //         break;
  //       }
  //     }
  //   }
  // }
  searchgroup() {
    const searchedValue = this.Search.trim().toLowerCase();

    let data;
    if (this.myTree != undefined) {
        data = this.myTree.getItems();
    } else {
        return;
    }

    let foundItem = [];
    let expandedParents = new Set(); 
    data.forEach(res => {
        const labelText = res.label.toLowerCase();
        const textDiv = res.titleElement[0]; 

        textDiv.querySelectorAll('.highlightedText').forEach(span => {
            span.outerHTML = span.innerText;
        });

        if (searchedValue !== '' && labelText.includes(searchedValue)) {
            foundItem.push(res.element);
            this.myTree.expandItem(res.parentElement);
            expandedParents.add(res.parentElement);
            textDiv.innerHTML = textDiv.innerHTML.replace(
                new RegExp(searchedValue, 'gi'),
                match => `<span class="highlightedText" style="font-weight: bold;">${match}</span>`
            );
        }
    });

      // data.forEach(res => {
      //     if (!expandedParents.has(res.parentElement) && !foundItem.includes(res.parentElement) && res.label != "Select all") {
      //             this.myTree.collapseItem(res.parentElement);
      //     }
      // });
    

    

  if (foundItem.length > 0) {
    if(foundItem[0].id != -1){
      this.myTree.selectItem(foundItem[0]);
      this.myTree.ensureVisible(foundItem[0]);
    }
  }

  if (searchedValue === '') {
    for (const res of data) {
        foundItem = res; 
        this.myTree.expandAll();
        this.myTree.selectItem(null);
        this.myTree.ensureVisible(res.element);
        break;
    }
}
}


  associationmultiGeofence(replace) {
    let geofencedata = { geozoneData: {}, vin: [] };
    var genozonedata = { Road: [], Freeform: [], Circle: [], Landmark: [] };
    var geozoneType = [];
    var jsonMails = {};

    let geozone = this.geofenceTree.getCheckedItems();
    if (geozone != null && geozone != undefined && geozone.length != 0) {
      for (let i in geozone) {
        if (
          geozone[i].value.split("-")[0] != "Circle" &&
          geozone[i].value.split("-")[0] != "Road" &&
          geozone[i].value.split("-")[0] != "Freeform" &&
          geozone[i].value.split("-")[0] != "Landmark"
        ) {
          let zonetype = geozone[i].parentElement.innerText
            .replace("\n", " ")
            .split(" ");
          let type = zonetype[0];
          if (type == "Road") {
            if (genozonedata.Road.values)
              genozonedata.Road.push(geozone[i].value.split("-")[0]);

            if (geozoneType.includes("Road") == false) geozoneType.push("Road");
          } else if (type == "Landmark") {
            if (genozonedata.Landmark.values)
              genozonedata.Landmark.push(geozone[i].value.split("-")[0]);

            if (geozoneType.includes("Landmark") == false)
              geozoneType.push("Landmark");
          } else if (type == "Circle") {
            if (genozonedata.Circle.values)
              genozonedata.Circle.push(geozone[i].value.split("-")[0]);

            if (geozoneType.includes("Circle") == false)
              geozoneType.push("Circle");
          } else if (type == "Freeform") {
            if (genozonedata.Freeform.values)
              genozonedata.Freeform.push(geozone[i].value.split("-")[0]);

            if (geozoneType.includes("Freeform") == false)
              geozoneType.push("Freeform");
          }
        }
      }
    } else this.commonService.presentAlert("Error", "Please Select GeoZone");

    let platenumbers = this.myTree.getCheckedItems();
    if (platenumbers.length != 0) {
      for (let vin = 0; vin < platenumbers.length; vin++) {
        if (platenumbers[vin].value != platenumbers[vin].label) {
          if (genozonedata.Road.length != 0) {
            for (let z = 0; z < genozonedata.Road.length; z++) {
              geofencedata.vin.push(
                platenumbers[vin].value + "#" +
                genozonedata.Road[z] + "#" +
                "Road#" +
                platenumbers[vin].label
              );
            }
          }
          if (genozonedata.Landmark.length != 0) {
            for (let z = 0; z < genozonedata.Landmark.length; z++) {
              geofencedata.vin.push(
                platenumbers[vin].value +
                "#" +
                genozonedata.Landmark[z] +
                "#" +
                "Landmark#" +
                platenumbers[vin].label
              );
            }
          }
          if (genozonedata.Circle.length != 0) {
            for (let z = 0; z < genozonedata.Circle.length; z++) {
              geofencedata.vin.push(
                platenumbers[vin].value +
                "#" +
                genozonedata.Circle[z] +
                "#" +
                "Circle#" +
                platenumbers[vin].label
              );
            }
          }
          if (genozonedata.Freeform.length != 0) {
            for (let z = 0; z < genozonedata.Freeform.length; z++) {
              geofencedata.vin.push(
                platenumbers[vin].value +
                "#" +
                genozonedata.Freeform[z] +
                "#" +
                "Freeform#" +
                platenumbers[vin].label
              );
            }
          }
        }
      }
    }

    if (this.mode == "add" || this.mode == "edit") {

      (geofencedata.geozoneData["userId"] = localStorage.getItem("userName")),
        (geofencedata.geozoneData["GZonEnter"] =
          this.checkonenter == "false" || this.checkonenter == "" ? false : true);
      geofencedata.geozoneData["GZonLeave"] =
        this.checkonleave == "false" || this.checkonleave == "" ? false : true;
      geofencedata.geozoneData["GZstatus"] =
        this.checkStatus == "false" || this.checkStatus == "" ? false : true;
      geofencedata.geozoneData["GZpush"] = true;
      geofencedata.geozoneData["mode"] = this.mode;

      if (this.selectedDays != 0)
        geofencedata.geozoneData["alertDay"] =
          this.selectedDays == undefined ? 127 : this.selectedDays;
      else this.commonService.presentAlert("Error", "Please Select Days");

      if (this.validfor != "")
        geofencedata.geozoneData["validFor"] = this.validfor;
      else this.commonService.presentAlert("Error", "Please Select Valid For");

      if (this.checksendbetwn != "") {
        if (this.checksendbetwn == "Except 12:00 AM to 06:00 AM")
          geofencedata.geozoneData["sendBetween"] = "06:01_23:59";
        else geofencedata.geozoneData["sendBetween"] = "0:00_23:59";
      } else geofencedata.geozoneData["sendBetween"] = "06:01_23:59";

      var number = this.mobilenumber;
      if (number != "" && number != undefined) {
        if (number.includes(",")) {
          let phnums = number.split(",");
          if (phnums[0].length < 11 && phnums[1].replace(" ", "").length < 11) {
            geofencedata.geozoneData["textSms"] = phnums[0];
            geofencedata.geozoneData["textSms1"] = phnums[1];
            geofencedata.geozoneData["throughSms"] = true;
            geofencedata.geozoneData["throughSms1"] = true;
            geofencedata.geozoneData["GZsms"] = true;
          } else
            this.commonService.presentAlert("Error", "Enter Max 10 Numbers");
        } else {
          if (number.length < 11) {
            geofencedata.geozoneData["textSms"] = number;
            geofencedata.geozoneData["textSms1"] = "";
            geofencedata.geozoneData["throughSms"] = true;
            geofencedata.geozoneData["throughSms1"] = false;
            geofencedata.geozoneData["GZsms"] = true;
          } else
            this.commonService.presentAlert("Error", "Enter Max 10 Numbers");
        }
      } else {
        geofencedata.geozoneData["textSms"] = "";
        geofencedata.geozoneData["textSms1"] = "";
        geofencedata.geozoneData["throughSms"] = false;
        geofencedata.geozoneData["throughSms1"] = false;
      }

      var mail = this.mailaddress;
      if (mail != "" && mail != undefined) {
        if (mail.includes(",")) {
          let mailids = mail.split(",");
          if (this.commonService.mailValidation.test(mailids[0])) {
            geofencedata.geozoneData["textEmail"] = mailids[0];
            jsonMails["email1"] = mailids[0]
          }
          else {
            this.commonService.presentToast(this.commonService.mailerror)
            return "";
          }
          if (this.commonService.mailValidation.test(mailids[1])) {
            geofencedata.geozoneData["textEmail1"] = mailids[1];
            jsonMails["email1"] = jsonMails["email1"] + "," + mailids[1]
          }
          else {
            this.commonService.presentToast(this.commonService.mailerror)
            return "";
          }
          geofencedata.geozoneData["throughEmail"] = true;
          geofencedata.geozoneData["throughEmail1"] = true;
          geofencedata.geozoneData["GZemail"] = true;
        } else {
          if (this.commonService.mailValidation.test(mail)) {
            geofencedata.geozoneData["textEmail"] = mail;
            jsonMails["email1"] = mail
          } else {
            this.commonService.presentToast(this.commonService.mailerror)
            return "";
          }
          geofencedata.geozoneData["textEmail1"] = "";
          geofencedata.geozoneData["throughEmail"] = true;
          geofencedata.geozoneData["throughEmail1"] = false;
          geofencedata.geozoneData["GZemail"] = true;
        }
      } else {
        geofencedata.geozoneData["textEmail"] = "";
        geofencedata.geozoneData["textEmail1"] = "";
        geofencedata.geozoneData["throughEmail"] = false;
        geofencedata.geozoneData["throughEmail1"] = false;
        geofencedata.geozoneData["GZemail"] = false;
      }
      //console.log(geofencedata);

    } else {
      geofencedata.geozoneData["userId"] = localStorage.getItem("userName");
      geofencedata.geozoneData["mode"] = "multi";

      if (this.checkonenter != "") geofencedata.geozoneData["GZonEnter"] = true;
      if (this.checkonleave != "") geofencedata.geozoneData["GZonLeave"] = true;
      if (this.checkStatus != "") geofencedata.geozoneData["GZstatus"] = true;
      if (this.selectedDays != 0)
        geofencedata.geozoneData["alertDay"] =
          this.selectedDays == undefined ? 127 : this.selectedDays;
      if (this.validfor != "")
        geofencedata.geozoneData["validFor"] = this.validfor;
      if (this.checksendbetwn != "") {
        if (this.checksendbetwn != "") {
          if (this.checksendbetwn == "Except 12:00 AM to 06:00 AM")
            geofencedata.geozoneData["sendBetween"] = "06:01_23:59";
          else geofencedata.geozoneData["sendBetween"] = "0:00_23:59";
        }
      }
      if (this.mobilenumber != "") {
        var number = this.mobilenumber;
        if (number != "" && number != undefined) {
          if (number.includes(",")) {
            let phnums = number.split(",");
            if (
              phnums[0].length < 11 &&
              phnums[1].replace(" ", "''").length < 11
            ) {
              geofencedata.geozoneData["textSms"] = phnums[0];
              geofencedata.geozoneData["textSms1"] = phnums[1];
              geofencedata.geozoneData["throughSms"] = true;
              geofencedata.geozoneData["throughSms1"] = true;
              geofencedata.geozoneData["GZsms"] = true;
            } else
              this.commonService.presentAlert("Error", "Enter Max 10 Numbers");
          } else {
            if (number.length < 11) {
              geofencedata.geozoneData["textSms"] = number;
              geofencedata.geozoneData["textSms1"] = "";
              geofencedata.geozoneData["throughSms"] = true;
              geofencedata.geozoneData["throughSms1"] = false;
              geofencedata.geozoneData["GZsms"] = true;
            } else
              this.commonService.presentAlert("Error", "Enter Max 10 Numbers");
          }
        } else {
          geofencedata.geozoneData["textSms"] = "";
          geofencedata.geozoneData["textSms1"] = "";
          geofencedata.geozoneData["throughSms"] = false;
          geofencedata.geozoneData["throughSms1"] = false;
        }
      }

      if (this.mailaddress != "") {


        var mail = this.mailaddress;
        if (mail != "" && mail != undefined) {
          if (mail.includes(",")) {
            let mailids = mail.split(",");
            if (this.commonService.mailValidation.test(mailids[0])) {
              geofencedata.geozoneData["textEmail"] = mailids[0];
              jsonMails["email1"] = mailids[0]

            }
            else {
              this.commonService.presentToast(this.commonService.mailerror)
              return "";
            }
            if (this.commonService.mailValidation.test(mailids[1])) {
              geofencedata.geozoneData["textEmail1"] = mailids[1];
              jsonMails["email1"] = jsonMails["email1"] + "," + mailids[1]
            }
            else {
              this.commonService.presentToast(this.commonService.mailerror)
              return "";
            }
            geofencedata.geozoneData["throughEmail"] = true;
            geofencedata.geozoneData["throughEmail1"] = true;
            geofencedata.geozoneData["GZemail"] = true;
          } else {
            if (this.commonService.mailValidation.test(mail)) {
              geofencedata.geozoneData["textEmail"] = mail;
              jsonMails["email1"] = mail
            }
            else {
              this.commonService.presentToast(this.commonService.mailerror)
              return "";
            }
            geofencedata.geozoneData["textEmail1"] = "";
            geofencedata.geozoneData["throughEmail"] = true;
            geofencedata.geozoneData["throughEmail1"] = false;
            geofencedata.geozoneData["GZemail"] = true;
          }
        } else {
          geofencedata.geozoneData["textEmail"] = "";
          geofencedata.geozoneData["textEmail1"] = "";
          geofencedata.geozoneData["throughEmail"] = false;
          geofencedata.geozoneData["throughEmail1"] = false;
          geofencedata.geozoneData["GZemail"] = false;
        }
      }
      //console.log(geofencedata);
    }



    if (this.mode == "edit" || this.mode == "multi") {
      let oldvalue = {};
      oldvalue["validfor"] = this.selectedValue.includes('validfr') ? this.selectedRow.validFor : undefined;
      oldvalue["alertDay"] = this.selectedValue.includes('dayvalues') ? this.selectedRow.alertDay : undefined;
      oldvalue["sendBetween"] = this.selectedValue.includes('betweenvalues') ? this.selectedRow.alertTime : undefined;
      oldvalue["GZonEnter"] = this.selectedRow.onEnter == this.checkonenter || this.checkonenter == "" ? undefined : this.selectedRow.onEnter;
      oldvalue["GZonLeave"] = this.selectedRow.onLeave == this.checkonleave || this.checkonleave == "" ? undefined : this.selectedRow.onLeave;
      oldvalue["GZstatus"] = this.selectedRow.status == this.checkStatus || this.checkStatus == "" ? undefined : this.selectedRow.status;
      oldvalue["textSms"] = this.selectedRow.mobileNumber == geofencedata.geozoneData["textSms"] ? undefined : this.selectedRow.mobileNumber;
      oldvalue["textSms1"] = this.selectedRow.mobileNumber1 == geofencedata.geozoneData["textSms1"] ? undefined : this.selectedRow.mobileNumber1;
      oldvalue["textEmail"] = this.selectedRow.emailAddress == geofencedata.geozoneData["textEmail"] ? undefined : this.selectedRow.emailAddress;
      oldvalue["textEmail1"] = this.selectedRow.emailAddress1 == geofencedata.geozoneData["textEmail1"] ? undefined : this.selectedRow.emailAddress1;
      oldvalue["macIp"] = "";
      geofencedata["companyId"] = localStorage.corpId;
      geofencedata["userId"] = localStorage.getItem("userName")
      geofencedata["branchId"] = localStorage.corpId
      geofencedata["oldValue"] = JSON.stringify(oldvalue);
    }

    jsonMails["data"] = localStorage.corpId;
    if (Object.keys(jsonMails).includes("email1")) {
      let serviceData = JSON.stringify(jsonMails);

      let url: string = serverUrl.web + "/alert/emailvalidation/" + serviceData;
      this.commonService.presentLoader();
      this.ajaxService.ajaxGetWithString(url)
        .subscribe(res => {
          this.commonService.dismissLoader();
          var json = JSON.parse(res);
          if (!Object.values(json).includes(false))
            this.saveZoneWithVehicle(geofencedata);
          else
            this.commonService.presentToast(this.commonService.mailerror)
        })
    } else
      this.saveZoneWithVehicle(geofencedata);

  }

  saveZoneWithVehicle(data) {
    if (data.vin.length !== 0) {
      const url = serverUrl.web + "/alert/saveZoneSericeDatas";
      this.ajaxService.ajaxPutMethod(url, data).subscribe(
        (res) => {
          this.modalController.dismiss();
          this.ajaxService.ajaxPostWithBody(serverUrl.Admin + "/api/alerts/geoconfig", { vins: this.myTree.getCheckedItems().toString() })
            .subscribe(res => {

            })
          this.commonService.presentToast("Geofence associated successfully");
        },
        (err) => {
          console.log(err);
        }
      );
    }
  }

  deleteZonewithVahicle() {
    let zoneForDelete = [];
    let obj = {};
    obj["vin"] = this.selectedRow.vin;
    obj["zoneid"] = this.selectedRow.id.split("#")[0];
    zoneForDelete["forZoneDelete"] = obj;
    zoneForDelete["geozoneType"] = this.selectedRow.type;
  }

  renderPlateNo() {
    this.geoJsonId = 1;
    for (let i = 0; i < this.dashboardJson.length; i++) {
      let groupArray: any = [];
      groupArray =
        this.dashboardJson[i].Group == null ||
          this.dashboardJson[i].Group == undefined
          ? ["None group"]
          : this.dashboardJson[i].Group.split(",");
      let filterTextFormat = "";
      for (let j = 0; j < groupArray.length; j++) {
        filterTextFormat += groupArray[j];
        if (this.filterData[filterTextFormat] == undefined) {
          this.filterData[filterTextFormat] = this.geoJsonId;
          this.geoJsonId++;
        }
        if (groupArray.length - 1 != j) filterTextFormat += ",";
      }
      groupArray =
        this.dashboardJson[i].Group == null
          ? "None group"
          : this.dashboardJson[i].Group;

      this.treeStructureObject[this.dashboardJson[i].plateNo] = {
        id: this.geoJsonId.toString(),
        parentid: this.filterData[groupArray].toString(),
        text: this.dashboardJson[i].plateNo.split("@,@").length >= 2 ? this.dashboardJson[i].plateNo.split("@,@")[0] + ` (${this.dashboardJson[i].plateNo.split("@,@")[1]})` : this.dashboardJson[i].plateNo,
        value: this.dashboardJson[i].vin,
      };

      this.treeStructureJson.push({
        id: this.geoJsonId.toString(),
        parentid: this.filterData[groupArray].toString(),
        text: this.dashboardJson[i].plateNo.split("@,@").length >= 2 ? this.dashboardJson[i].plateNo.split("@,@")[0] + ` (${this.dashboardJson[i].plateNo.split("@,@")[1]})` : this.dashboardJson[i].plateNo,
        value: this.dashboardJson[i].vin,
      });

      this.geoJsonId++;
    }
    for (let i = 0; i < Object.keys(this.filterData).length; i++) {
      let loopedArry: any =
        Object.keys(this.filterData)[i].split(",").length - 1;
      loopedArry = Object.keys(this.filterData)[i].split(",")[loopedArry];
      var popData = Object.keys(this.filterData)[i].split(",")
      popData.pop()
      this.treeStructureObject[loopedArry] = {
        id: Object.values(this.filterData)[i].toString(),
        parentid:
          Object.keys(this.filterData)[i].split(",").length > 1
            ? this.filterData[
            popData.toString()
            ]
            : "-1",
        text: loopedArry,
        value: loopedArry,
      };

      this.treeStructureJson.push({
        id: Object.values(this.filterData)[i].toString(),
        parentid:
          Object.keys(this.filterData)[i].split(",").length > 1
            ? this.filterData[
            popData.toString()
            ]
            : "-1",
        text: loopedArry,
        value: loopedArry,
      });
    }
    this.dataAdapter = new jqx.dataAdapter(this.source, { autoBind: true });
    this.records = this.dataAdapter.getRecordsHierarchy(
      "id",
      "parentid",
      "items",
      [{ name: "text", map: "label" }]
    );
  }

  createMap() {
    this.map = this.mapService.loadMap(
      this.mapElement.nativeElement,
      { lat: 24.440099, lng: 46.843498 },
      false,
      false
    );
    this.mapService.setCenter(this.map, { lat: 24.440099, lng: 46.843498 });
  }

  getGeofence() {
    let url = serverUrl.web + "/Alert/GeozoneDatas?data=" + localStorage.corpId;
    this.ajaxService.ajaxGetJson(url).subscribe((res) => {
      for (let i = 0; i < Object.keys(res).length; i++) {
        this.treeGeofenceObject[Object.keys(res)[i]] = {
          id: this.geoJsonId.toString(),
          parentid: "-1",
          text: this.trans.instant(Object.keys(res)[i]),
          value: Object.keys(res)[i],
        };
        this.geofenceGridForm.push({
          id: this.geoJsonId.toString(),
          parentid: "-1",
          text: this.trans.instant(Object.keys(res)[i]),
          value: Object.keys(res)[i],
        });
        let selectedGeoValue: any = Object.values(res[Object.keys(res)[i]]);
        let selectedGeoKeys: any = Object.keys(res[Object.keys(res)[i]]);
        let currentParentId = this.geoJsonId;
        for (let j = 0; j < selectedGeoValue.length; j++) {
          var newId = ++this.geoJsonId;
          selectedGeoValue[j]["type"] = Object.keys(res)[i];
          selectedGeoValue[j]["zoneLatLng"] = JSON.stringify(
            selectedGeoValue[j].latlng
          );
          this.treeGeofenceObject[selectedGeoKeys[j]] = {
            parentid: currentParentId,
            id: newId.toString(),
            text: this.trans.instant(selectedGeoValue[j].name),
            value: selectedGeoKeys[j],
            json: selectedGeoValue[j],
          };
          this.geofenceGridForm.push({
            parentid: currentParentId,
            id: newId.toString(),
            text: selectedGeoValue[j].name,
            value: selectedGeoKeys[j],
          });
        }
        this.geoJsonId++;
      }

      var source = {
        datatype: "json",
        datafields: [
          { name: "id" },
          { name: "parentid" },
          { name: "text" },
          { name: "value" },
        ],
        id: "id",
        localdata: this.geofenceGridForm,
      };

      this.dataAdapter = new jqx.dataAdapter(source, { autoBind: true });
      this.geofence = this.dataAdapter.getRecordsHierarchy(
        "id",
        "parentid",
        "items",
        [{ name: "text", map: "label" }]
      );
      setTimeout((res) => {
        if (this.mode == "edit" || this.mode == "multi") {
          this.geofenceTree.expandAll();
          let selectRow = document.getElementById(
            this.treeGeofenceObject[this.selectedRow.id.split("#")[0]].id
          );
          this.geofenceTree.checkItem(selectRow, true);
          for (var n in this.multiRow) {
            let selectRows = document.getElementById(
              this.treeGeofenceObject[this.multiRow[n].id.split("#")[0]].id
            );
            this.geofenceTree.checkItem(selectRows, true);
          }

          if (this.multiRow[0].type == "Circle") {
            this.createCircle(this.multiRow[0]);
          } else if (this.multiRow[0].type == "Road") {
            this.createRoadForm(this.multiRow[0]);
          } else if (this.multiRow[0].type == "Freeform") {
            this.createFreeForm(this.multiRow[0]);
          } else if (this.multiRow[0].type == "Landmark") {
            this.createLandMark(this.multiRow[0]);
          }
          this.geofenceTree.disabled(true);
        }
        this.geofenceTree.expandAll();
      }, 1000);
    });
  }

  createLandMark(selectedRow) {
    let data = JSON.parse(selectedRow.zoneLatLng);
    let latLng = {
      lat: parseFloat(data.split(",")[0]),
      lng: parseFloat(data.split(",")[1]),
    };
    this.mapService.addMarker(
      this.map,
      latLng,
      "geoFence",
      "assets/vtstrackhisIcon/Idle.png"
    );
  }
  createRoadForm(selectedRow) {
    let data = JSON.parse(selectedRow.zoneLatLng);
    let latLngData = [];
    for (let i in data) {
      var latlng = data[i];
      latLngData.push({
        latitude: latlng.split(",")[0],
        longitude: latlng.split(",")[1],
      });
    }
    this.mapService.setRoadLineString(this.map, latLngData);
  }

  createFreeForm(selectedRow) {
    let data = JSON.parse(selectedRow.zoneLatLng);
    let latLngData = [];
    for (let i in data) {
      var latlng = data[i];
      latLngData.push({
        latitude: latlng.split(",")[0],
        longitude: latlng.split(",")[1],
      });
    }
    latLngData.push({
      latitude: data[0].split(",")[0],
      longitude: data[0].split(",")[1],
    });
    this.mapService.setRoadLineString(this.map, latLngData);
  }

  selectAndCheckGeoZone(event) {
    if (this.Search == "")
      this.myTree.checkItem(
        event.args.element,
        !this.myTree.getItem(event.args.element).checked
      );
  }

  selectAndCheck(event) {
    this.geofenceTree.checkItem(
      event.args.element,
      !this.geofenceTree.getItem(event.args.element).checked
    );
    this.selectGeofence(event);
  }

  createCircle(selectedRow) {
    let selectValue = "Prefered";
    let latLng;
    let range;
    if (selectedRow.Area === "Restricted") {
      selectValue = "Restricted";
    }
    const detailsString = JSON.parse(selectedRow.zoneLatLng);
    const getLatLng = detailsString.split("|");
    const southwest = getLatLng[0].split(",");
    const northeast = getLatLng[1].split(",");
    const south = parseFloat(southwest[0].split(",")[0]);
    const west = parseFloat(southwest[1].split(",")[0]);
    const north = parseFloat(northeast[0].split(",")[0]);
    const east = parseFloat(northeast[1].split(",")[0]);
    latLng = { lat: south, lng: west };
    range = this.calculateRadius(north, east, south, west);
    this.mapService.addMarker(
      this.map,
      latLng,
      "geoFence" + detailsString.name,
      "assets/vtstrackhisIcon/Idle.png"
    );
    this.mapService.createCircle(this.map, latLng, range, selectValue);
    this.mapService.fitBounds(this.map, 2);
  }

  calculateRadius = function (south, west, north, east) {
    const R = 6378.137; // Radius of earth in KM
    const dLat = ((north - south) * Math.PI) / 180;
    const dLon = ((east - west) * Math.PI) / 180;
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos((south * Math.PI) / 180) *
      Math.cos((north * Math.PI) / 180) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const d = R * c;
    return Math.round(d * 1000);
  };

  selectGeofence(event) {
    if (this.geofenceTree.getCheckedItems().length > 0) {
      this.mapService.clearLayers(this.map, [1, 2]);
      var id = this.geofenceTree.getCheckedItems();
      for (let i = 0; i < id.length; i++) {
        var selectedRow = this.treeGeofenceObject[id[i].value].json;
        if (selectedRow) {
          if (selectedRow.type == "Circle") {
            this.createCircle(selectedRow);
          } else if (selectedRow.type == "Road") {
            this.createRoadForm(selectedRow);
          } else if (selectedRow.type == "Freeform") {
            this.createFreeForm(selectedRow);
          } else if (selectedRow.type == "Landmark") {
            this.createLandMark(selectedRow);
          }
        }
      }
      this.mapService.fitBounds(this.map, 2);
    } else {
      if (this.map) this.mapService.clearLayers(this.map, [1, 2]);
    }
  }

  closemodel() {
    this.modalController.dismiss();
  }

  getSelectedValue(name) {
    if (this.selectedValue.includes(!name))
      this.selectedValue.push(name)
  }

  ngOnInit() {
    this.renderPlateNo();
    this.getGeofence();
    setTimeout(() => {
      this.createMap();
    }, 500);
    this.commonService.updateLanguageDir
    .subscribe(res => {
      this.directory = res == "" ? this.directory : res
    })
  }
}
