import { Component, OnInit, ViewChild, Input } from "@angular/core";
import { AjaxService } from "../../services/ajax.service";
import { CommonService } from "../../services/common.service";
import {
  NavParams,
  ModalController,
  Platform,
  MenuController,
} from "@ionic/angular";
import { AuthMapService } from "../../services/auth-map.service";
import { GoogleMapService } from "../../services/google-map.service";
import { OpenlayerMapService } from "../../services/openlayer-map.service";
import { app, serverUrl,languageInitializer } from "src/environments/environment";
import { toLonLat } from "ol/proj";
import { Router } from "@angular/router";
import { map } from "rxjs/operators";
import { TranslateService } from "@ngx-translate/core";
import { TranslateConfigService } from "../../services/translate-config.service";
declare var google;
@Component({
  selector: "app-geofence-model",
  templateUrl: "./geofence-model.page.html",
  styleUrls: ["./geofence-model.page.scss"],
})
export class GeofenceModelPage implements OnInit {
  @Input() mode: string;
  @Input() vin: string;
  @Input() vinData: JSON;
  selectedVinData = JSON.parse(localStorage.getItem("selectedVin"));
  layerModal = false;
  //header = this.selectedVinData.plateNo;
  searchMaplatLng: any = "";
  map;
  paramVin: string;
  type: string;
  range = 500;
  click = 1;
  editableData;
  zone = { zoneName: "" };
  selectValue = "Preferred";
  typeSelector = [
    { id: "Preferred", name: "Preferred" },
    { id: "Restricted", name: "Restricted" },
  ];
  additionalInfo = { "speed": "", "height": "", "type": "", "start": "", end: "" }
  @ViewChild("mapElement", { static: false }) mapElement;
  subscription: any;
  shape: string = "Circle";
  // myPlatform: string;
  pointLatlng: any;
  showFence: any = "All";
  directory: string = languageInitializer.directory;
  constructor(
    public nav: NavParams,
    public modalCtrl: ModalController,
    public mapService: AuthMapService,
    public commonService: CommonService,
    public ajaxService: AjaxService,
    public gMapService: GoogleMapService,
    public olMapService: OpenlayerMapService,
    private modalController: ModalController,
    private menuController: MenuController,
    private platform: Platform,
    private router: Router,
    public translateConfigService: TranslateConfigService,
    public trans: TranslateService
  ) {
    this.mapService = new OpenlayerMapService();
  }

  ionViewWillEnter() {
    this.subscription = this.platform.backButton.subscribe(async () => {
      if (this.menuController.isOpen()) {
        this.menuController.close();
      }
    });
  }

  ionViewWillLeave() {
    this.subscription.unsubscribe();
  }

  createMap(mode) {
    this.map = this.mapService.loadMap(
      this.mapElement.nativeElement,
      { lat: 24.440099, lng: 46.843498 },
      false,
      false
    );
    this.mapService.setCenter(this.map, { lat: 24.440099, lng: 46.843498 });
    let latLng;
    if (mode === "add") {
      latLng = { lat: 24.440099, lng: 46.843498 };
      this.onClickLocationGetter();
      this.range = 500;
      this.mapService.addMarker(
        this.map,
        latLng,
        "geoFence",
        "assets/vtstrackhisIcon/Idle.png"
      );
      this.mapService.createCircle(
        this.map,
        latLng,
        this.range,
        this.selectValue
      );
      this.mapService.fitBounds(this.map, 2);
    } else {
      // edit part
      this.zone.zoneName = this.editableData.zone;
      this.selectValue = this.editableData.area
        ? this.editableData.area
        : "Preferred";
      if (this.editableData.type == "geoFence") {
        this.formCircleEdit();
        this.onClickLocationGetter();
      } else if (
        this.editableData.type == "road" ||
        this.editableData.type == "freeForm"
      )
        this.formFreeRoadEdit();
      else if (this.editableData.type == "landMark") {
        this.formLandMarkEdit();
        this.onClickLocationGetter();
      }
    }
  }
  formLandMarkEdit() {
    let data = this.editableData.latlng;
    this.shape = "Point";
    let latLng = {
      lat: parseFloat(data.split(",")[0]),
      lng: parseFloat(data.split(",")[1]),
    };
    this.mapService.addMarker(
      this.map,
      latLng,
      "geoFence",
      "assets/vtstrackhisIcon/Idle.png"
    );
  }

  formFreeRoadEdit() {
    let latLngData = [];
    if (this.editableData.type == "road") {
      this.shape = "LineString";
      this.shapeChange("LineString");
    }
    if (this.editableData.type == "freeForm") {
      this.shape = "Polygon";
      this.shapeChange("Polygon");
    }
    let data = this.editableData.latlngs;

    for (let i in data) {
      var latlng = data[i];
      latLngData.push({
        latitude: latlng.split(",")[0],
        longitude: latlng.split(",")[1],
      });
    }
    if (this.editableData.type == "freeForm")
      if (this.editableData.latlngs[0])
        latLngData.push({
          latitude: this.editableData.latlngs[0].split(",")[0],
          longitude: this.editableData.latlngs[0].split(",")[1],
        });
      else {
        latLngData.push({
          latitude: this.editableData.latlngs[1].split(",")[0],
          longitude: this.editableData.latlngs[1].split(",")[1],
        });
      }
    this.mapService.setRoadLineString(this.map, latLngData);
  }

  formCircleEdit() {
    this.shape = "Circle";
    let latLng;
    this.onClickLocationGetter();
    this.zone.zoneName = this.editableData.zone;
    const detailsString = this.editableData.latlng;
    console.log("editlatLng", detailsString);
    const getLatLng = detailsString.split("|");
    const southwest = getLatLng[0].split(",");
    const northeast = getLatLng[1].split(",");
    const south = parseFloat(southwest[0].split(",")[0]);
    const west = parseFloat(southwest[1].split(",")[0]);
    const north = parseFloat(northeast[0].split(",")[0]);
    const east = parseFloat(northeast[1].split(",")[0]);
    latLng = { lat: south, lng: west };
    this.range = this.calculateRadius(north, east, south, west);
    this.mapService.addMarker(
      this.map,
      latLng,
      "geoFence",
      "assets/vtstrackhisIcon/Idle.png"
    );
    this.mapService.createCircle(
      this.map,
      latLng,
      this.range,
      this.selectValue
    );
    this.mapService.fitBounds(this.map, 2);
  }

  calculateRadius = function (south, west, north, east) {
    const R = 6378.137; // Radius of earth in KM
    const dLat = ((north - south) * Math.PI) / 180;
    const dLon = ((east - west) * Math.PI) / 180;
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos((south * Math.PI) / 180) *
      Math.cos((north * Math.PI) / 180) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const d = R * c;
    return Math.round(d * 1000);
  };

  closeModel() {
    this.modalCtrl.dismiss();
  }

  setRange(range) {
    this.range = range;
    this.mapService.setCircleRadius(this.range);
  }

  circleEdit(geoBounds) {
    let data: any = {};
    if (this.additionalInfo.speed != "")
      data['speed'] = this.additionalInfo.speed;
    if (this.additionalInfo.height != "")
      data['height'] = this.additionalInfo.height;
    if (this.additionalInfo.start != "" && this.additionalInfo.start != "")
      data['timePeriod'] = this.additionalInfo.start + "-" + this.additionalInfo.end;
    else if ((this.additionalInfo.start != "" && this.additionalInfo.start == "") || (this.additionalInfo.start == "" && this.additionalInfo.start != "")) {
      this.commonService.presentToast("Enter valid time")
      return null
    }
    let detailsForUpdate = {
      type: this.selectValue,
      latLng: geoBounds,
      shape: "Circle",
      zoneType: "geoZone",
      status: JSON.stringify(data),
      undefined: "",
      name: this.zone.zoneName.trim(),
      userID: localStorage.getItem("userName"),
      id: this.editableData.id.toString().split("-")[0],
      companyID: localStorage.getItem("corpId"),
      branchID: localStorage.getItem("corpId"),
      macIp: ""
    };
    const url = serverUrl.web + "/VehicleController/editGeoFence";
    this.ajaxService.ajaxPostWithBody(url, detailsForUpdate).subscribe(
      (res) => {
        this.modalController.dismiss();
        console.log(res);
        this.commonService.dismissLoader();
        this.succSaveZone(res.geoStatus);
      },
      (err) => {
        console.log(err);
        this.commonService.dismissLoader();
      }
    );
  }

  saveGeoFence() {
    if (this.zone.zoneName === undefined || this.zone.zoneName === "") {
      this.commonService.presentAlert("Empty", "Enter your zone name");
    } else {
      if (this.shape == "Circle") {
        this.saveCircleGeoFence();
      }
      if (this.shape == "Polygon") {
        this.saveFreeForm();
      }
      if (this.shape == "LineString") {
        this.saveRoadForm();
      }
      if (this.shape == "Point") {
        this.saveLandMark();
      }
    }
  }

  saveLandMark() {
    let detailsForUpdate = {};
    console.log(this.zone.zoneName);
    this.commonService.presentLoader();
    console.log("geobounds", this.pointLatlng);
    if (this.type === "edit") {
      this.landMarkEdit(this.pointLatlng);
    } else {
      let data: any = {};
      if (this.additionalInfo.speed != "")
        data['speed'] = this.additionalInfo.speed;
      if (this.additionalInfo.height != "")
        data['height'] = this.additionalInfo.height;
      if (this.additionalInfo.start != "" && this.additionalInfo.start != "")
        data['timePeriod'] = this.additionalInfo.start + "-" + this.additionalInfo.end;
      else if ((this.additionalInfo.start != "" && this.additionalInfo.start == "") || (this.additionalInfo.start == "" && this.additionalInfo.start != "")) {
        this.commonService.presentToast("Enter valid time")
        return null
      }
      detailsForUpdate = {
        type: this.selectValue,
        // latLng: this.pointLatlng,
        zoneType: "landMark",
        shape: "landMark",
        name: this.zone.zoneName.trim(),
        userID: localStorage.getItem("userName"),
        companyID: localStorage.getItem("corpId"),
        branchID: localStorage.getItem("corpId"),
        status: JSON.stringify(data),
        description: "",
      };
      if (this.searchMaplatLng) {
        detailsForUpdate["latLng"] = this.searchMaplatLng;
      } else {
        detailsForUpdate["latLng"] = this.pointLatlng;
      }

      this.commonService.dismissLoader();
      const url = serverUrl.web + "/VehicleController/addGeoFence";
      this.ajaxService.ajaxPostWithBody(url, detailsForUpdate).subscribe(
        (res) => {
          this.modalController.dismiss();
          console.log(res);
          this.commonService.dismissLoader();
          this.succSaveZone(res.geoStatus);
        },
        (err) => {
          console.log(err);
          this.commonService.dismissLoader();
        }
      );
    }
  }
  landMarkEdit(fence) {
    let detailsForUpdate = {};
    console.log(this.zone.zoneName);
    this.commonService.presentLoader();
    console.log("geobounds", this.pointLatlng);
    let data: any = {};
    if (this.additionalInfo.speed != "")
      data['speed'] = this.additionalInfo.speed;
    if (this.additionalInfo.height != "")
      data['height'] = this.additionalInfo.height;
    if (this.additionalInfo.start != "" && this.additionalInfo.start != "")
      data['timePeriod'] = this.additionalInfo.start + "-" + this.additionalInfo.end;
    else if ((this.additionalInfo.start != "" && this.additionalInfo.start == "") || (this.additionalInfo.start == "" && this.additionalInfo.start != "")) {
      this.commonService.presentToast("Enter valid time")
      return null
    }
    detailsForUpdate = {
      type: this.selectValue,
      latLng: this.pointLatlng ? this.pointLatlng : this.editableData.latlng,
      zoneType: "landMark",
      shape: "landMark",
      name: this.zone.zoneName.trim(),
      id: this.editableData.id.toString().split("-")[0],
      userID: localStorage.getItem("userName"),
      companyID: localStorage.getItem("corpId"),
      branchID: localStorage.getItem("corpId"),
      status: JSON.stringify(data),
      description: "",
      macIp: ""
    };
    this.commonService.dismissLoader();
    const url = serverUrl.web + "/VehicleController/editGeoFence";
    this.ajaxService.ajaxPostWithBody(url, detailsForUpdate).subscribe(
      (res) => {
        this.modalController.dismiss();
        console.log(res);
        this.commonService.dismissLoader();
        this.succSaveZone(res.geoStatus);
      },
      (err) => {
        console.log(err);
        this.commonService.dismissLoader();
      }
    );
  }
  saveRoadForm() {
    let detailsForUpdate = {};
    console.log(this.zone.zoneName);
    const geoBounds = this.mapService.getRoadFromCoordinate(this.map);
    console.log("geobounds", geoBounds);
    if (geoBounds.length == 0) {
      this.commonService.presentToast("Please draw the Roadform");
      return null;
    }
    this.commonService.presentLoader();
    if (this.type === "edit") {
      this.roadFormEdit(geoBounds);
    } else {
      let data: any = {};
      if (this.additionalInfo.speed != "")
        data['speed'] = this.additionalInfo.speed;
      if (this.additionalInfo.height != "")
        data['height'] = this.additionalInfo.height;
      if (this.additionalInfo.start != "" && this.additionalInfo.start != "")
        data['timePeriod'] = this.additionalInfo.start + "-" + this.additionalInfo.end;
      else if ((this.additionalInfo.start != "" && this.additionalInfo.start == "") || (this.additionalInfo.start == "" && this.additionalInfo.start != "")) {
        this.commonService.presentToast("Enter valid time")
        return null
      }
      detailsForUpdate = {
        type: this.selectValue,
        latLng: JSON.stringify(geoBounds),
        zoneType: "road",
        shape: "road",
        name: this.zone.zoneName.trim(),
        userID: localStorage.getItem("userName"),
        companyID: localStorage.getItem("corpId"),
        branchID: localStorage.getItem("corpId"),
        status: JSON.stringify(data),
      };
      this.commonService.dismissLoader();
      const url = serverUrl.web + "/VehicleController/addGeoFence";
      this.ajaxService.ajaxPostWithBody(url, detailsForUpdate).subscribe(
        (res) => {
          this.modalController.dismiss();
          console.log(res);
          this.commonService.dismissLoader();
          this.succSaveZone(res.geoStatus);
        },
        (err) => {
          console.log(err);
          this.commonService.dismissLoader();
        }
      );
    }
  }

  saveFreeForm() {
    let detailsForUpdate = {};
    console.log(this.zone.zoneName);
    const geoBounds = this.mapService.getPolygonCoordinates(this.map);
    console.log("geobounds", geoBounds);
    if (geoBounds.length == 0) {
      this.commonService.presentToast("Please draw the Freeform");
      return null;
    }
    this.commonService.presentLoader();
    if (this.type === "edit") {
      this.freeFormEdit(geoBounds);
    } else {
      let data: any = {};
      if (this.additionalInfo.speed != "")
        data['speed'] = this.additionalInfo.speed;
      if (this.additionalInfo.height != "")
        data['height'] = this.additionalInfo.height;
      if (this.additionalInfo.start != "" && this.additionalInfo.start != "")
        data['timePeriod'] = this.additionalInfo.start + "-" + this.additionalInfo.end;
      else if ((this.additionalInfo.start != "" && this.additionalInfo.start == "") || (this.additionalInfo.start == "" && this.additionalInfo.start != "")) {
        this.commonService.presentToast("Enter valid time")
        return null
      }
      detailsForUpdate = {
        type: this.selectValue,
        latLng: JSON.stringify(geoBounds),
        zoneType: "freeform",
        shape: "freeForm",
        name: this.zone.zoneName.trim(),
        userID: localStorage.getItem("userName"),
        companyID: localStorage.getItem("corpId"),
        branchID: localStorage.getItem("corpId"),
        status: JSON.stringify(data),
      };
      const url = serverUrl.web + "/VehicleController/addGeoFence";
      this.ajaxService.ajaxPostWithBody(url, detailsForUpdate).subscribe(
        (res) => {
          this.modalController.dismiss();
          console.log(res);
          this.commonService.dismissLoader();
          this.succSaveZone(res.geoStatus);
        },
        (err) => {
          console.log(err);
          this.commonService.dismissLoader();
        }
      );
    }
  }

  saveCircleGeoFence() {
    let detailsForUpdate = {};
    console.log(this.zone.zoneName);
    this.commonService.presentLoader();
    const geoBounds = this.mapService.circleGeoZone();
    console.log("geobounds", geoBounds);
    if (this.type === "edit") {
      this.circleEdit(geoBounds);
    } else {
      let data: any = {};
      if (this.additionalInfo.speed != "")
        data['speed'] = this.additionalInfo.speed;
      if (this.additionalInfo.height != "")
        data['height'] = this.additionalInfo.height;
      if (this.additionalInfo.start != "" && this.additionalInfo.start != "")
        data['timePeriod'] = this.additionalInfo.start + "-" + this.additionalInfo.end;
      else if ((this.additionalInfo.start != "" && this.additionalInfo.start == "") || (this.additionalInfo.start == "" && this.additionalInfo.start != "")) {
        this.commonService.presentToast("Enter valid time")
        return null
      }
      detailsForUpdate = {
        type: this.selectValue,
        latLng: geoBounds,
        zoneType: "geoZone",
        shape: "Circle",
        name: this.zone.zoneName.trim(),
        userID: localStorage.getItem("userName"),
        companyID: localStorage.getItem("corpId"),
        branchID: localStorage.getItem("corpId"),
        status: JSON.stringify(data),
      };
      const url = serverUrl.web + "/VehicleController/addGeoFence";
      this.ajaxService.ajaxPostWithBody(url, detailsForUpdate).subscribe(
        (res) => {
          this.modalController.dismiss();
          console.log(res);
          this.commonService.dismissLoader();
          this.succSaveZone(res.geoStatus);
        },
        (err) => {
          console.log(err);
          this.commonService.dismissLoader();
        }
      );
    }
  }

  freeFormEdit(fence) {
    let detailsForUpdate = {};
    const geoBounds = this.mapService.getPolygonCoordinates(this.map);
    let data: any = {};
    if (this.additionalInfo.speed != "")
      data['speed'] = this.additionalInfo.speed;
    if (this.additionalInfo.height != "")
      data['height'] = this.additionalInfo.height;
    if (this.additionalInfo.start != "" && this.additionalInfo.start != "")
      data['timePeriod'] = this.additionalInfo.start + "-" + this.additionalInfo.end;
    else if ((this.additionalInfo.start != "" && this.additionalInfo.start == "") || (this.additionalInfo.start == "" && this.additionalInfo.start != "")) {
      this.commonService.presentToast("Enter valid time")
      return null
    }
    detailsForUpdate = {
      type: this.selectValue,
      latLng: JSON.stringify(geoBounds),
      id: this.editableData.id.toString().split("-")[0],
      name: this.zone.zoneName.trim(),
      userID: localStorage.getItem("userName"),
      companyID: localStorage.getItem("corpId"),
      branchID: localStorage.getItem("corpId"),
      status: JSON.stringify(data),
      macIp: ""
    };
    detailsForUpdate["zoneType"] = "freeform";
    detailsForUpdate["shape"] = "freeform";

    const url = serverUrl.web + "/VehicleController/editGeoFence";
    this.ajaxService.ajaxPostWithBody(url, detailsForUpdate).subscribe(
      (res) => {
        this.modalController.dismiss();
        console.log(res);
        this.commonService.dismissLoader();
        this.succSaveZone(res.geoStatus);
      },
      (err) => {
        console.log(err);
        this.commonService.dismissLoader();
      }
    );
  }

  roadFormEdit(fence) {
    let detailsForUpdate = {};
    const geoBounds = this.mapService.getRoadFromCoordinate(this.map);
    let data: any = {};
    if (this.additionalInfo.speed != "")
      data['speed'] = this.additionalInfo.speed;
    if (this.additionalInfo.height != "")
      data['height'] = this.additionalInfo.height;
    if (this.additionalInfo.start != "" && this.additionalInfo.start != "")
      data['timePeriod'] = this.additionalInfo.start + "-" + this.additionalInfo.end;
    else if ((this.additionalInfo.start != "" && this.additionalInfo.start == "") || (this.additionalInfo.start == "" && this.additionalInfo.start != "")) {
      this.commonService.presentToast("Enter valid time")
      return null
    }
    detailsForUpdate = {
      type: this.selectValue,
      latLng: JSON.stringify(geoBounds),
      id: this.editableData.id.toString().split("-")[0],
      name: this.zone.zoneName.trim(),
      userID: localStorage.getItem("userName"),
      companyID: localStorage.getItem("corpId"),
      branchID: localStorage.getItem("corpId"),
      status: JSON.stringify(data),
      macIp: ""
    };
    detailsForUpdate["zoneType"] = "road";
    detailsForUpdate["shape"] = "road";

    const url = serverUrl.web + "/VehicleController/editGeoFence";
    this.ajaxService.ajaxPostWithBody(url, detailsForUpdate).subscribe(
      (res) => {
        this.modalController.dismiss();
        console.log(res);
        this.commonService.dismissLoader();
        this.succSaveZone(res.geoStatus);
      },
      (err) => {
        console.log(err);
        this.commonService.dismissLoader();
      }
    );
  }

  succSaveZone(data) {
    if (data === "success" || data === "persisted") {
      let mode = "added";
      if (this.type === "edit") {
        mode = "updated";
      }
      this.commonService.presentToast("Your, Zone " + mode + " successfully");
    } else {
      this.commonService.presentAlert("Error", "Zone not saved successfully");
      this.closeModel();
    }
  }

  getAddressLatlng(address) {
    if (address != "") {
      let data: any = parseFloat(address.split(",")[0]);
      if (data.toString() != "NaN") {
        if (address.split(",")[1]) {
          this.searchMaplatLng = address;
          this.mapService.clearLayers(this.map, [1, 2]);
          this.mapService.setCenter(this.map, {
            lat: parseFloat(address.split(",")[0]),
            lng: parseFloat(address.split(",")[1]),
          });

          let latLng2;
          latLng2 = { lat: parseFloat(address.split(",")[0]), lng: parseFloat(address.split(",")[1]) };

          this.mapService.addMarker(this.map, latLng2, "addGeoZone", "assets/vtstrackhisIcon/Idle.png");
          console.log("this.shape", this.shape);
          if (this.shape == "Circle") {
            this.mapService.createCircle(
              this.map,
              latLng2,
              this.range,
              this.selectValue
            )
          }


        }
      } else if (data.toString() == "NaN") {
        this.ajaxService
          .ajaxGet(
            "https://nominatim.openstreetmap.org/?format=json&addressdetails=1&q=" +
            address +
            "&format=json&limit=1"
          )
          .subscribe((res) => {
            if (res.length > 0) {
              let latLng = { lat: res[0].lat, lng: res[0].lon };
              this.mapService.setCenter(this.map, {
                lat: parseFloat(res[0].lat),
                lng: parseFloat(res[0].lon),
              });
              // this.mapService.addMarker(this.map, latLng, 'geoFence', 'assets/vtstrackhisIcon/Idle.png');
              // this.mapService.createCircle(this.map, latLng, this.range, this.selectValue);
              // this.mapService.fitBounds(this.map, 2);
            } else
              this.commonService.presentToast(
                "Can't able to get your location"
              );
          });
      }
    } else {
      this.searchMaplatLng = "";
      this.mapService.clearLayers(this.map, [1, 2]);
    }
  }

  ngOnInit() {
    this.paramVin = this.vin;
    this.type = this.mode;
    this.editableData = this.vinData;
    // console.log(this.editableData);
    // this.myPlatform = this.platform.platforms()[0];
    // if (this.myPlatform == "tablet") {
    //   this.myPlatform = "desktop";
    // }
    this.mode == "edit" ? (this.showFence = this.editableData.type) : (this.showFence = "All");
    if (app.entryPoint == "WFT") {
      this.shape = "Polygon"
      this.showFence = "freeForm"
    }

    if (this.mode == "edit") {
      if (this.editableData.status != "" && JSON.parse(this.editableData.status).speed)
        this.additionalInfo.speed = JSON.parse(this.editableData.status).speed
      if (this.editableData.status != "" && JSON.parse(this.editableData.status).height)
        this.additionalInfo.height = JSON.parse(this.editableData.status).height
      if (this.editableData.status != "" && JSON.parse(this.editableData.status).timePeriod) {
        this.additionalInfo.start = JSON.parse(this.editableData.status).timePeriod.split("-")[0]
        this.additionalInfo.end = JSON.parse(this.editableData.status).timePeriod.split("-")[1]
      }
    }
    setTimeout(() => {
      this.createMap(this.type);
      if (app.entryPoint == "WFT" && this.mode != "edit") {
        this.shapeChange('Polygon')
      }
    }, 1000);

    this.commonService.updateLanguageDir
    .subscribe(res => {
      this.directory = res == "" ? this.directory : res
    })

  }

  getBack() {
    this.modalController.dismiss();
    // this.vtsGeofenceComponent.getGeoFenceData('prefer')
  }

  shapeChange(shape) {

    this.shape = shape;
    this.mapService.clearLayers(this.map, [1, 2]);
    if (shape != "Circle" && shape != "Point") {
      this.mapService.polyLineClear(this.map);
      this.mapService.setToPolygon(this.map, shape, this.selectValue);
    }
  }

  switchMap() {
    this.layerModal = !this.layerModal
  this.map.getLayers().item(3).setVisible(this.layerModal);
    this.map.getLayers().item(0).setVisible(!this.layerModal);
  }
  clearMap() {
    this.mapService.clearLayers(this.map, [1, 2]);
    this.shapeChange(this.shape);
  }
  onClickLocationGetter() {
    this.map.on("click", async (evt) => {
      if (this.shape == "Circle") {
        this.mapService.clearLayers(this.map, [1, 2]);
        const coordinates = evt.coordinate;
        let latLng = toLonLat([coordinates[0], coordinates[1]]);
        latLng = { lat: latLng[1], lng: latLng[0] };
        if (this.click > 1) {
          this.mapService.clearLayers(this.map, [1, 2]);
        }
        this.mapService.addMarker(
          this.map,
          latLng,
          "addGeoZone",
          "assets/vtstrackhisIcon/Idle.png"
        );
        this.mapService.createCircle(
          this.map,
          latLng,
          this.range,
          this.selectValue
        );
      }
      if (this.shape == "Point") {
        this.mapService.clearLayers(this.map, [1, 2]);
        const coordinates = evt.coordinate;
        let latLng = toLonLat([coordinates[0], coordinates[1]]);
        this.pointLatlng = latLng[1] + "," + latLng[0];
        latLng = { lat: latLng[1], lng: latLng[0] };
        if (this.click > 1) {
          this.mapService.clearLayers(this.map, [1, 2]);
        }

        this.mapService.addMarker(
          this.map,
          latLng,
          "addGeoZone",
          "assets/vtstrackhisIcon/Idle.png"
        );
      }
      this.click++;
    });
  }
}
