import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import { ModalController, NavParams } from '@ionic/angular';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-rac',
  templateUrl: './rac.component.html',
  styleUrls: ['./rac.component.scss'],
})
export class RACComponent implements OnInit {

  // @Input() rac;
  token;
  sanToken: SafeResourceUrl;

  constructor(private modalController: ModalController,
    // private navParams: NavParams,
    private sanitizer: DomSanitizer,
    private commonService: CommonService,
    private router: Router,
  ) {

  }

  closeModel() {
    this.modalController.dismiss();
  }
  sanitizedToken(): SafeResourceUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(this.token);
  }
  ionViewWillEnter() {
    console.log("jsbdjb");

  }


  ngOnInit() {
    // const racParam = this.navParams.get('rac');
    // if (racParam) {
    //   this.token = 'https://ge-fleetonqa.thegoldenelement.com/#/airportadmin/' + racParam;
    //   this.sanToken = this.sanitizedToken();
    // } else {
    //   console.error('Invalid or missing rac parameter');
    // }
    // const racParam = this.navParams.get('rac');
    // if (this.commonService.RACres) {


    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if(localStorage.pageSelector == 'Maintenance'){
          if (window.location.origin == "https://mvt.thegoldenelement.com") {
            this.token = 'https://mvt.thegoldenelement.com/fm/#/fm';
          } else if (window.location.origin == "https://mrac.thegoldenelement.com") {
            this.token = 'https://mrac.thegoldenelement.com/fm/#/fm';
          }else if (window.location.origin == "https://arabiantracking.net") {
            this.token = 'https://arabiantracking.net/fm/#/fm';
          } else {
            this.token = 'https://qa-one.thegoldenelement.com/fm/#/fm';
          }
          this.sanToken = this.sanitizedToken();
          this.commonService.presentLoader();
          setTimeout(() => {
            this.commonService.dismissLoader();
          }, 5000);
        }
        }
    });
    // } else {
    // console.error('Invalid or missing rac parameter');
    // }

  }


}
