import { Component, OnInit, ViewChild } from '@angular/core';
import { AlertController, ModalController, Platform } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { jqxGridComponent } from 'jqwidgets-ng/jqxgrid';
import { SimDeviceModalComponent } from 'src/app/components/sim-device-modal/sim-device-modal.component';
import { AjaxService } from 'src/app/services/ajax.service';
import { CommonService } from 'src/app/services/common.service';
import { languageInitializer, serverUrl } from 'src/environments/environment';
import { ExportExcelService } from '../../services/export-excel.service';

@Component({
  selector: 'app-vts-sim',
  templateUrl: './vts-sim.component.html',
  styleUrls: ['./vts-sim.component.scss'],
})
export class VtsSimComponent implements OnInit {
  source: { localdata: any; };
  tableData: any;
  renderer: (row: number, column: any, value: string) => string;
  dataAdapter: any;
  @ViewChild('myGrid', { static: false }) myGrid: jqxGridComponent;
  columns: { text: string; datafield: any; cellsrenderer: (row: number, column: any, value: string) => string; }[];
  selectedRow: any;
  directory: string = languageInitializer.directory;
  localizationobj: {};
  pdfdatas = [];
  myPlatform: any;
  pagesizeoption = ["5", "10", "13", "15", "20"];
  submitbtn: boolean = false;

  constructor(
    private ajaxService: AjaxService,
    private modalController: ModalController,
    private commonService: CommonService,
    private trans: TranslateService,
    public platform: Platform,
    private ete: ExportExcelService,
    private alertController: AlertController
  ) { }
  head = ['SIM Card Number', 'IMEI Number', 'Date of Purchase', 'Provider', 'Plan', 'PUK Code', 'Support Number', 'Status'];


  myGridOnRowSelect(event) {
    this.selectedRow = event.args.row
    console.log("this.selectedRow", this.selectedRow);

  }

  async deleteSim() {
    if (this.selectedRow.imeiNo)
      this.commonService.presentToast("Associated simcard cannot be deleted")
    else if (!this.selectedRow.imeiNo) {
      const alert = await this.alertController.create({
        header: this.trans.instant('Are you sure?'),
        backdropDismiss: false,
        message: this.trans.instant("Do you want to delete your Record?. If you delete it, your changes will be lost."),
        buttons: [{
          text: this.trans.instant('Cancel'),
          role: 'cancel',
          handler: data => {
          }
        },
        {
          text: this.trans.instant('Ok'),
          handler: datas => {
            const url = serverUrl.web + "/DCFramework/deleteSimCard"
            const data = {
              "compnyID": localStorage.corpId,
              "branchID": localStorage.corpId,
              "user": localStorage.userName,
              "simCardNo": this.selectedRow.simCardNo,
              "macIp": ""
            }
            this.ajaxService.ajaxDeleteWithBody(url, data)
              .subscribe(res => {
                this.commonService.presentToast("Sim card deleted successfully")
                this.myGrid.clearselection();
                this.selectedRow = "";
                this.renderGridTable();
              })
          }
        }]
      });
      await alert.present();
    }
  }

  async openSimModel() {
    const model = await this.modalController.create({
      component: SimDeviceModalComponent,
      cssClass: this.directory != 'rtl' ? 'user_feature' : 'user_feature-rtl',
      componentProps: {
        page: "Sim",
        mode: "Add"
      }
    })
    model.onDidDismiss().then(() => {
      this.myGrid.clearselection();
      this.selectedRow = "";
      this.renderGridTable();
    })
    await model.present();
  }

  async editSimModal() {
    if (this.selectedRow) {
      const model = await this.modalController.create({
        component: SimDeviceModalComponent,
        cssClass: this.directory != 'rtl' ? 'user_feature' : 'user_feature-rtl',
        componentProps: {
          page: "Sim",
          mode: 'edit',
          selectedRow: this.selectedRow
        }
      })
      model.onDidDismiss().then(() => {
        this.myGrid.clearselection();
        this.selectedRow = "";
        this.renderGridTable();
      })
      await model.present();
    } else {
      this.commonService.presentToast("please select one row")
    }
  }

  renderGridTable() {
    this.commonService.presentLoader();
    let url = serverUrl.web + "/DCFramework/getSimCardDetails?compId=" + localStorage.corpId + "&userId="+localStorage.userName;
    this.ajaxService.ajaxGetJson(url)
      .subscribe(res => {
        this.tableData = res;
        this.pdfdatas = [];
        for (var i = 0; i < this.tableData.length; i++) {
          this.pdfdatas.push([this.tableData[i].simCardNo, this.tableData[i].imeiNo, this.tableData[i].DOP, this.tableData[i].provider, this.tableData[i].plan, this.tableData[i].pukCode, this.tableData[i].supportNo, this.tableData[i].warrantyExpiry === "true" ? (this.tableData[i].warrantyExpiry = 'suspend') : (this.tableData[i].warrantyExpiry = 'active')]);
        }
        this.tableData.map(res => {
          if (!res["DOP"])
            res["DOP"] = ""
        })
        this.renderer = (row: number, column: any, value: string,) => {
          if (value == "" || null || undefined) {
            return this.trans.instant("----No Data----")
          }
          else {
            return '<span  style="line-height:32px;font-size:11px;color:darkblue;margin:auto;padding:0px 5px">' + value + '</span>';
          }
        }
        this.source = { localdata: this.tableData };
        this.dataAdapter = new jqx.dataAdapter(this.source);
        this.columns = [
          { text: this.trans.instant('Sim card no'), datafield: 'simCardNo', cellsrenderer: this.renderer },
          { text: this.trans.instant('Imei no'), datafield: 'imeiNo', cellsrenderer: this.renderer },
          { text: this.trans.instant('Date of purchase'), datafield: "DOP", cellsrenderer: this.renderer },
          // { text: 'Expriy date', datafield: 'expriydate', cellsrenderer: this.renderer },
          { text: this.trans.instant('Provider'), datafield: 'provider', cellsrenderer: this.renderer },
          { text: this.trans.instant('Plan'), datafield: 'plan', cellsrenderer: this.renderer },
          { text: this.trans.instant('Puk code'), datafield: 'pukCode', cellsrenderer: this.renderer },
          { text: this.trans.instant('Support number'), datafield: 'supportNo', cellsrenderer: this.renderer },
          { text: this.trans.instant('Status'), datafield: 'warrantyExpiry', cellsrenderer: this.renderer }

        ]
        this.myGrid.pagesizeoptions(this.pagesizeoption)
      })
    this.commonService.dismissLoader();
  }

  async createPdf() {
    // For filtered rows
    const filteredRows = this.myGrid.getrows();
    const filteredData = filteredRows.map(row => {
      const obj = {};
      obj["SIM Card Number"] = row.simCardNo;
      obj["IMEI Number"] = row.imeiNo;
      obj["Date of Purchase"] = row.DOP;
      obj["Provider"] = row.provider;
      obj["Plan"] = row.plan;
      obj["PUK Code"] = row.pukCode;
      obj["Support Number"] = row.supportNo;
      obj["Status"] = row.warrantyExpiry;
      return obj;
    });
    //For filtered rows
    this.commonService.createPdf(this.head, this.pdfdatas, filteredData, "SIM Card Details", this.myPlatform, "SIM Card Details", null)
  }


  exportToExcel() {
    // For filtered rows
    const filteredRows = this.myGrid.getrows();
    const filteredData = filteredRows.map(row => {
      const obj = {};
      obj["SIM Card Number"] = row.simCardNo;
      obj["IMEI Number"] = row.imeiNo;
      obj["Date of Purchase"] = row.DOP;
      obj["Provider"] = row.provider;
      obj["Plan"] = row.plan;
      obj["PUK Code"] = row.pukCode;
      obj["Support Number"] = row.supportNo;
      obj["Status"] = row.warrantyExpiry;
      return obj;
    });
    //For filtered rows
    let reportData = {
      title: 'SIM Card Details',
      data: this.pdfdatas,
      headers: this.head,
      data1: filteredData
    }
    this.ete.exportExcel(reportData);
    //console.log("Export Excel")
  }


  ngOnInit() {
    if (localStorage.manageADM == "true" && localStorage.companyRole == "FleetManager") {
      this.submitbtn = true;
    }
    this.myPlatform = this.platform.platforms()[0];
    if (this.myPlatform == 'tablet') {
      this.myPlatform = 'desktop';
    }
    this.renderGridTable();
    this.localizationobj = {
      groupsheaderstring: this.trans.instant("Drag a column and drop it here to group by that column"),
    };
  }

}
