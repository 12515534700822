import { Component, OnInit, ViewChild } from '@angular/core';
import { AlertController, ModalController } from '@ionic/angular';
import { jqxGridComponent } from 'jqwidgets-ng/jqxgrid';
import { Platform } from '@ionic/angular';
import { ExpenseFormComponent } from 'src/app/expense-maintenance/expense-form/expense-form.component'
import { CommonService } from 'src/app/services/common.service';
import { serverUrl } from 'src/environments/environment';
import { AjaxService } from 'src/app/services/ajax.service';
import { ExportExcelService } from 'src/app/services/export-excel.service';
@Component({
  selector: 'app-expense-table',
  templateUrl: './expense-table.component.html',
  styleUrls: ['./expense-table.component.scss'],
})
export class ExpenseTableComponent implements OnInit {
  @ViewChild('myGrid', { static: false }) myGrid: jqxGridComponent;
  columns: ({ text: string; datafield: string; cellsrenderer: (row: number, column: any, value: string) => string; cellsalign: string; align: string; } | { text: string; datafield: string; cellsrenderer?: undefined; })[];
  source: { localdata: any; };
  dataAdapter: any;
  renderer: (row: number, column: any, value: string) => string;
  myPlatform: any;
  isDeleteShow: any = true;
  selectedRow: any;
 
  tableData:any;
  pdfdatas: any;
  head = ['Plate No', 'Expense', 'Date', 'Price', 'Description'];
  exportTitle:'Expense maintenance';
  constructor(
    public platform: Platform,
    private modalController: ModalController,private alertController:AlertController,
    private commonService: CommonService,
    private ajaxService: AjaxService,
    private ete: ExportExcelService,
  ) { }
  createPdf() {
    this.commonService.createPdf(this.head, this.pdfdatas,null, this.exportTitle, this.myPlatform, 'Expense maintenance', null);
  }
  
  exportToExcel() {
    let reportData = {
      title: 'Expense maintenance',
      data: this.pdfdatas,
      headers: this.head
    }
    this.ete.exportExcel(reportData);
  }
  ngAfterViewInit() {
    if (this.myPlatform == 'desktop') {
      this.myGrid.showloadelement();
    }
    this.getDatas();
  }
  getDatas() {
    if (this.myPlatform == 'desktop') {
      const companyDetail = {
        branchID: localStorage.getItem('corpId'),
        companyID: localStorage.getItem('corpId'),
        userId: localStorage.getItem('userName')
      }
    }
var url=serverUrl.web + '/expense/getExpense?companyId='+localStorage.getItem('corpId');
this.ajaxService.ajaxGet(url).subscribe(res => {
  var detail = res;
  this.tableData = res
  this.pdfdatas = [];
  for (var i = 0; i < detail.length; i++) {
    this.pdfdatas.push([detail[i].plateNo, detail[i].expense,detail[i].fromDate, detail[i].price, detail[i].description]);
  }
    this.renderer = (row: number, column: any, value: string,) => {
      if (value == "" || null || undefined || value == ",") {
        return "---"
      }
      else {
        return '<span  style="line-height:32px;font-size:11px;color:darkblue;margin:auto;">' + value + '</span>';
      }
    }

    this.source = { localdata: this.tableData };
    this.dataAdapter = new jqx.dataAdapter(this.source);
    this.columns = [
      { text: 'Plate No', datafield: 'plateNo', cellsrenderer: this.renderer, cellsalign: 'center', align: 'center' },
      { text: 'Expense', datafield: 'expense', cellsrenderer: this.renderer , cellsalign: 'center', align: 'center'},
      { text: 'Date', datafield: 'fromDate', cellsrenderer: this.renderer , cellsalign: 'center', align: 'center'},
      { text: 'Price', datafield: 'price', cellsrenderer: this.renderer, cellsalign: 'center', align: 'center' },
      { text: 'Description', datafield: 'description', cellsrenderer: this.renderer , cellsalign: 'center', align: 'center'},
    ]
  })

  }
  
  myGridOnRowSelect(event: any): void {
    this.selectedRow = event.args.row;
  }
  async openModel()
  {
    const modal = await this.modalController.create({
      component:ExpenseFormComponent,
      cssClass: 'maintenance'
    });
    modal.onDidDismiss().then(() => {
      if (this.myPlatform == "desktop") {
        this.myGrid.clearselection();
      }
this.getDatas();
    })
    return await modal.present();
  }
  async editModel(selectedCard)
  {
    if (this.myPlatform != "desktop") {
      if (selectedCard) {
        // selectedCard["submit"] = "available"
        const modal = await this.modalController.create({
          component:ExpenseFormComponent,
          componentProps: {
            value: selectedCard
          }
          });
          modal.onDidDismiss().then(() => {
            // this.myGrid.clearselection();
            // selectedCard = "";
            this.getDatas();
          });
        return await modal.present();
      }

    }
    else  {
      if (this.selectedRow) {
        const modal = await this.modalController.create({
          component:ExpenseFormComponent,
          cssClass: 'maintenance',
          componentProps: {
            value: this.selectedRow
          }
        });
        modal.onDidDismiss().then(() => {
          this.myGrid.clearselection();
          this.selectedRow = "";
          this.getDatas();
        })

        return await modal.present();
      }
      else {
        this.commonService.presentToast('Please select a row to edit');
        return "";
      }

    }
  }
  async deleteModel()
  {
    if (this.selectedRow) {
      const alert = await this.alertController.create({
        header: ' delete',
        backdropDismiss: false,
        message: "Are you sure you want to delete?",
        buttons: [{
          text: 'Cancel',
          role: 'cancel',
          handler: data => {
          }
        },
        {
          text: 'Ok',
          handler: data =>{
            const url = serverUrl.web + '/expense/deleteExpense?id=' + this.selectedRow.id;
            this.ajaxService.ajaxDeleteWithString(url).subscribe(res => {  
               if (res.message == "Deleted Successfully") {
                this.commonService.presentToast("Deleted successfully")
                this.myGrid.clearselection();
                this.getDatas();
                }
                else {
                      this.commonService.presentToast("Please contact support Team")
                    }
            })
          }
          
        }]
      });
      await alert.present();

    }
    else {
      this.commonService.presentToast('Please select a row to Delete');
      return "";
    }

  }

  ngOnInit() {
    this.myPlatform = this.platform.platforms()[0];
    if (this.myPlatform == 'tablet') {
      this.myPlatform = 'desktop';
    }
    this.getDatas();
  }
}
