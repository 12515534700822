import { Component, OnInit, Input } from '@angular/core';
import { AjaxService } from 'src/app/services/ajax.service';
import { ModalController, Platform, MenuController } from '@ionic/angular';
import { ActivatedRoute } from '@angular/router';
import { ReportsFormPage } from '../reports-form/reports-form.page';
import { languageInitializer, serverUrl } from 'src/environments/environment';
import { inputs } from '@syncfusion/ej2-angular-navigations/src/accordion/accordion.component';
import { CommonService } from 'src/app/services/common.service';


@Component({
  selector: 'app-vts-reports',
  templateUrl: './vts-reports.component.html',
  styleUrls: ['./vts-reports.component.scss'],
})
export class VtsReportsComponent implements OnInit {
  subscription: any;
  @Input() plateNo;
  @Input() value;
  // myPlatform: string;
  reportShowContent: object;
  reportName: string = 'Overall summary report';
  reportPlatNo: any;
  directory = languageInitializer.directory;
  selectReportTypes: any;
  showaddress: any;
  entryPoint: any;
  constructor(
    private ajaxService: AjaxService,
    private modalController: ModalController,
    private activatedRoute: ActivatedRoute,
    private platform: Platform,
    private menuController: MenuController,
    private commonService: CommonService
  ) { }
  reportTypeItems = new Array();


  modalControllerFunction() {
    this.modalController.dismiss(this.reportTypeItems);
  }

  async openForm(mode: string) {
    this.plateNo = this.plateNo;
    //  console.log(this.plateNo,"plate number")
    const shownContent: object = {};
    switch (mode) {
      case "Active status report":

        shownContent["plateNoAll"] = true;
        break;
      case "Overall summary report":
        shownContent["group"] = true;
        shownContent["plateNo"] = true;
        shownContent["fromDate"] = true;
        shownContent["toDate"] = true;
        shownContent["clocktime"] = true;//thas
        break;
      case "Engine Hours summary report":
        shownContent["group"] = true;
        shownContent["plateNo"] = true;
        shownContent["fromDate"] = true;
        shownContent["fromTime"] = true;
        shownContent["toDate"] = true;
        shownContent["toTime"] = true;
        shownContent["clocktime"] = true;//thas
        shownContent["addressCheckbox"] = true
        break;
      case "Trip summary report":
        shownContent["group"] = true;
        shownContent["plateNo"] = true;
        shownContent["fromDate"] = true;
        shownContent["fromTime"] = true;
        shownContent["toDate"] = true;
        shownContent["toTime"] = true;
        shownContent["addressCheckbox"] = true
        break;
      case "Executive summary report":
        shownContent["fromDate"] = true;
        shownContent["group"] = true;
        shownContent["clocktime"] = true;
        shownContent["toDate"] = true;
        shownContent["addition"] = true;
        shownContent["plateNo"] = true;
        if (this.entryPoint == "ATM") {
          shownContent["group"] = true;
          shownContent["plateNo"] = true;
          shownContent["clocktime"] = false;
        }
        // else{
        //   shownContent["clocktime"] = true;//thas
        //   shownContent["group"] = false;
        //   shownContent["plateNo"] = false;
        // }
        if (this.showaddress == "true") {
          shownContent["addressCheckbox"] = true
        }//thas
        break;
      case "Driver behaviour report":
        shownContent["fromDate"] = true;
        shownContent["addition"] = true;
        break;
      case "Status summary report":
        shownContent["group"] = true;
        shownContent["plateNo"] = true;
        shownContent["fromDate"] = true;
        shownContent["toDate"] = true;
        shownContent["clocktime"] = true;//thas
        shownContent["addition"] = true;

        // if(this.showaddress =="true"){
        //   shownContent["addressCheckbox"] = true}//thas
        break;
      case "Alert report":
        shownContent["group"] = true;
        shownContent["plateNo"] = true;
        shownContent["fromDate"] = true;
        shownContent["toDate"] = true;
        shownContent["fromTime"] = true;
        shownContent["toTime"] = true;
        if (this.entryPoint == "ATM") {
          shownContent["clocktime"] = false;
        } else {
          shownContent["clocktime"] = true;//thas
        }
        shownContent["Alert type"] = true;
        shownContent["addition"] = true;
        break;
      case "Productivity Report":
        shownContent["group"] = true;
        shownContent["plateNo"] = true;
        shownContent["fromDate"] = true;
        shownContent["toDate"] = true;
        shownContent["ignoredate"] = true;
        break;
      case "Fence Report":
        shownContent["group"] = true;
        shownContent["plateNo"] = true;
        shownContent["fromDate"] = true;
        shownContent["toDate"] = true;
        shownContent["fromTime"] = true;
        shownContent["toTime"] = true;
        if (this.entryPoint == "ATM") {
          shownContent["clocktime"] = false;
        } else {
          shownContent["clocktime"] = true;//thas
        }
        shownContent["Alert type"] = true;
        shownContent["addition"] = true;
        break;
      case "Maintenance Alert":
        shownContent["group"] = true;
        shownContent["plateNo"] = true;
        shownContent["fromDate"] = true;
        shownContent["toDate"] = true;
        shownContent["fromTime"] = true;
        shownContent["toTime"] = true;
        shownContent["clocktime"] = true;//thas
        shownContent["Alert type"] = true;
        shownContent["addition"] = true;
        break;
      case "Overspeed duration report":
        shownContent["group"] = true;
        shownContent["plateNo"] = true;
        shownContent["fromDate"] = true;
        shownContent["toDate"] = true;
        shownContent["clocktime"] = true;//thas
        shownContent["addition"] = true;
        break;

      case "Speed report":
        shownContent["group"] = true;
        shownContent["plateNo"] = true;
        shownContent["fromDate"] = true;
        shownContent["clocktime"] = true;//thas
        shownContent["speed"] = true;
        shownContent["condition"] = true;
        break;

      case "Movement report":
        shownContent["group"] = true;
        shownContent["plateNo"] = true;
        shownContent["fromDate"] = true;
        shownContent["timeExceed"] = true;
        break;

      case "Temperature report":
        shownContent["group"] = true;
        shownContent["plateNo"] = true;
        shownContent["fromDate"] = true;
        shownContent["toDate"] = true;
        shownContent["sensor"] = true;
        shownContent["timeExceed"] = true;
        break;
      case "Alert MailReport":
        shownContent["group"] = true;
        shownContent["plateNo"] = true;
        shownContent["fromDate"] = true;
        shownContent["toDate"] = true;
        shownContent["fromTime"] = true;
        shownContent["toTime"] = true;
        break;
      case "Movement Report":
        shownContent["group"] = true;
        shownContent["plateNo"] = true;
        shownContent["fromDate"] = true;
        shownContent["toDate"] = true;
        shownContent["fromTime"] = true;
        shownContent["toTime"] = true;
        break;
      case "Status Summary Report":
        shownContent["group"] = true;
        shownContent["plateNo"] = true;
        shownContent["fromDate"] = true;
        shownContent["toDate"] = true;
        shownContent["fromTime"] = true;
        shownContent["toTime"] = true;
        break;
      case "Door open report":
        shownContent["group"] = true;
        shownContent["plateNo"] = true;
        shownContent["fromDate"] = true;
        shownContent["toDate"] = true;
        break;
      case "GSMLOST Report":
        shownContent["group"] = true;
        shownContent["plateNo"] = true;
        shownContent["fromDate"] = false;
        shownContent["toDate"] = false;
        shownContent["toTime"] = false;
        shownContent["fromTime"] = false;
        if (this.entryPoint == "ATM") {
          shownContent["datefilter"] = false;
        }
        break;
      case "User Activity Report":
        shownContent["group"] = false;
        shownContent["plateNo"] = false;
        shownContent["fromDate"] = true;
        shownContent["toDate"] = true;
        shownContent["toTime"] = true;
        shownContent["fromTime"] = true;
        shownContent["modes"] = true;
        shownContent["datatype"] = true;

        break;
      case "User Logs Report":
        shownContent["group"] = false;
        shownContent["plateNo"] = false;
        shownContent["fromDate"] = true;
        shownContent["toDate"] = true;
        shownContent["toTime"] = true;
        shownContent["fromTime"] = true;
        break;
      case "Door summary report":
        shownContent["group"] = true;
        shownContent["plateNo"] = true;
        shownContent["fromDate"] = true;
        shownContent["toDate"] = true;
        break;
      case "Door count report":
        shownContent["group"] = true;
        shownContent["fromDate"] = true;
        shownContent["toDate"] = true;
        break;
      case "Alarm report":
        shownContent["group"] = true;
        shownContent["plateNo"] = true;
        shownContent["fromDate"] = true;
        shownContent["toDate"] = true;
        break;
      case "Fuel consumption report":
        shownContent["group"] = true;
        shownContent["fromDate"] = true;
        shownContent["toDate"] = true;
        break;
      case "Odometer report":
        shownContent["group"] = true;
        shownContent["odometerWeek"] = true;
        break;
      case "Student Alert Report":
        shownContent["group"] = true;
        shownContent["fromDate"] = true;
        shownContent["toDate"] = true;
        shownContent["routetype"] = true;
        break;
      case "Attendance report":
        shownContent["group"] = true;
        shownContent["date"] = true;
        break;
      case "Ac Report":
        shownContent["group"] = true;
        shownContent["fromDate"] = true;
        shownContent["toDate"] = true;
        shownContent["plateNo"] = true;
        break;
      case "Stop report":
        shownContent["group"] = true;
        shownContent["plateNo"] = true;
        shownContent["fromDate"] = true;
        shownContent["fromTime"] = true;
        shownContent["toDate"] = true;
        shownContent["toTime"] = true;
        shownContent["clocktime"] = true;//thas
        shownContent["timeExceed"] = true;
        if (this.showaddress == "true") {
          shownContent["addressCheckbox"] = true
        }//thas
        shownContent["addition"] = true;
        break;
      case "Consolidate summary report":
        shownContent["group"] = true;
        shownContent["plateNo"] = true;
        shownContent["fromDate"] = true;
        shownContent["toDate"] = true;
        if (this.showaddress == "true") {
          shownContent["addressCheckbox"] = true
        }//thas
        shownContent["addition"] = true;
        break;
      case "No transmission report":
        // shownContent["plateNoAll"] = true;
        shownContent["group"] = true;
        shownContent["plateNo"] = true;
        shownContent["fromDate"] = false;
        shownContent["toDate"] = false;
        shownContent["toTime"] = false;
        shownContent["fromTime"] = false;

        if (this.entryPoint == "ATM") {
          shownContent["clocktime"] = false;
          shownContent["datefilter"] = false;

        } else {
          shownContent["clocktime"] = true;//thas
        }
        break;
      case "Vehicle movement report":
        shownContent["group"] = true;
        shownContent["plateNo"] = true;
        shownContent["fromDate"] = true;
        shownContent["fromTime"] = true;
        shownContent["toDate"] = true;
        shownContent["toTime"] = true;
        shownContent["clocktime"] = true;//thas
        if (this.showaddress == "true") {
          shownContent["addressCheckbox"] = true
        }//thas
        shownContent["addition"] = true;
        break;
      case "Idle violation report":
        shownContent["group"] = true;
        shownContent["plateNo"] = true;
        shownContent["fromDate"] = true;
        shownContent["toDate"] = true;
        shownContent["fromTime"] = true;
        shownContent["toTime"] = true;
        shownContent["clocktime"] = true;//thas
        shownContent["timeExceed"] = true;
        if (this.showaddress == "true") {
          shownContent["addressCheckbox"] = true
        }//thas
        shownContent["addition"] = true;
        break;
      case "Maintenance report":
        shownContent["group"] = true;
        break;
      case "Work summary report":
        shownContent["group"] = true;
        shownContent["plateNo"] = true;
        shownContent["fromDate"] = true;
        break;

      case "Fleet summary report":
        shownContent["group"] = true;
        shownContent["plateNo"] = true;

        break;
      case "Vehicle active status report":
        shownContent["plateNoAll"] = true;
        break;

      case "Recurrence":
        shownContent["allReportList"] = true;
        shownContent["group"] = true;
        shownContent["plateNo"] = true;
        // shownContent["clocktime"] = true;//thas
        shownContent["multipleEmail"] = true;
        shownContent["formatXlPdf"] = true;
        break;

      case "Employee no transmission report":
        shownContent["plateNoAll"] = true;
        break;

      case "Employee productivity report":
        shownContent["group"] = true;
        shownContent["plateNo"] = true;
        shownContent["fromDate"] = true;
        shownContent["toDate"] = true;
        shownContent["isMerge"] = true;
        break;

      case "Employee idle status report":
        shownContent["group"] = true;
        shownContent["plateNo"] = true;
        shownContent["fromDate"] = true;
        shownContent["toDate"] = true;
        shownContent["timeExceed"] = true;
        shownContent["isMerge"] = true;
        break;

      case "Employee day off report":
        shownContent["plateNoAll"] = true
        // shownContent["fromDate"] = true;
        // shownContent["toDate"] = true;
        break;

      case "Work out of zone report":
        shownContent["group"] = true;
        shownContent["plateNo"] = true;
        shownContent["fromDate"] = true;
        shownContent["fromTime"] = true;
        shownContent["toDate"] = true;
        shownContent["toTime"] = true;
        shownContent["timeExceed"] = true;
        shownContent["isMerge"] = true;
        break;

      case "No transmission report (WFT)":
        shownContent["slotWFT"] = true;
        shownContent["fromDate"] = true;
        shownContent["toDate"] = true;
        break;

      case "Operator Report"://thas
        //shownContent["slotWFT"] = true;
        shownContent["group"] = true;
        shownContent["plateNo"] = true;
        shownContent["clocktime"] = true;//thas
        // shownContent["fromDate"] = true;
        // shownContent["fromTime"] = true;
        // shownContent["toDate"] = true;
        // shownContent["toTime"] = true;
        break;


      case "Temperature Report":
        shownContent["group"] = true;
        shownContent["plateNo"] = true;
        shownContent["fromDate"] = true;
        shownContent["temperature"] = true;
        shownContent["clocktime"] = true;
        break;

      case "Fuel Report":
        shownContent["group"] = true;
        shownContent["plateNo"] = true;
        shownContent["fromDate"] = true;
        break;


    }
    if (this.commonService.myPlatform != 'desktop') {
      const modal = await this.modalController.create({
        component: ReportsFormPage,
        cssClass: this.directory != 'rtl' ? "" : "rtl-modal",
        componentProps: {
          shownContent: shownContent,
          reportName: mode,
          plateNo: this.plateNo
        }
      });
      return await modal.present();
    } else {
      this.reportShowContent = shownContent;
      this.reportName = mode;
      this.reportPlatNo = this.plateNo;
    }
  }

  getAvailableReports = () => {
    const body = {
      "companyId": localStorage.corpId,
      "userId": localStorage.userName
    }
    // const url = serverUrl.web+"/api/vts/company/branch/mainMenu/"+JSON.stringify(body);
    // this.ajaxService.ajaxGetWithBody(url)
    // .subscribe(
    //   res =>{
    var reports = JSON.parse(JSON.parse(localStorage.getItem('loginData'))[1].mainmenu);

    reports = reports.filter((c, index) => {
      return reports.indexOf(c) === index;
    });
    // const reports = ["Vehicle_Summary_By_Day","Vehicle_tripBy_summary", "Executive_Summary", "Driver_Behaviour", "Status_Summary", "Alert_Report","Over_Speed_Duration_Report", "Speed_Report", "Movement_Report","Temprature_Report", "Door_Summary", "Door_Open_Report", "Door_Count"];
    const reportjson = {
      "Executive_Summary": "Executive summary report",
      "Status_Summary": "Status summary report",
      "Over_Speed_Duration_Report": "Overspeed duration report",
      "Speed_Report": "Speed report",
      "Vehicle_Stop_Report": "Stop report",
      "Alert_Report": "Alert report",
      "Vehicle_Movement_Report": "Vehicle movement report",
      "Mokafha_Report": "Work summary report",
      "Fleet_Summery_Report": "Fleet summary report",
      "NO_Transmission_Report": 'No transmission report',
      "Vehicle_Summary_By_Day": 'Overall summary report',
      "Vehicle_tripBy_summary": "Engine Hours summary report",
      "Common_Report": "Consolidate summary report",
      "Vehicle_IdealViolation_Report": "Idle violation report",
      "Maintenance_Report": "Maintenance report",
      "Vehicle_Active_Status_Report": "Vehicle active status report",
      "Re_Occarance_Report": "Recurrence",
      "Employee_No_transmission_report": "Employee no transmission report",
      "Employee_Monthly_Discount_Report": "Employee productivity report",
      "Employee_Idle_Status_Report": "Employee idle status report",
      "Employee_Day_Off_Report": "Employee day off report",
      "Workout_Of_Zone_Report": "Work out of zone report",
      "No_Transmission_EmpOld_report": "No transmission report (WFT)",
      "zone_speed_violation_report": "Zone speed violation report",
      "Driver_behaviour_report": "Driver behaviour report",
      "Fence_Report": "Fence Report",
      "Maintenance_Alert": "Maintenance Alert",
      "Operator_Report": "Operator Report",//thas
      "GSMLOST_Report": "GSMLOST Report",
      "Alert_MailReport": "Alert MailReport",
      "Movement_Report": "Movement Report",
      "Status_Summary_Report": "Status Summary Report",
      "Temperature_Report": "Temperature Report",
      "Productivity_Report": "Productivity Report",
      "fuelReport": "Fuel Report",
      "Historydata": "User Activity Report",
      "LoginDetails": "User Logs Report",
    };
    //  const loginMenu = res.replace("[",'').replace("]",'').replace(/'/g,'').split(",");
    for (var i = 0; i < reports.length; i++) {
      if (reportjson.hasOwnProperty(reports[i])) {
        this.reportTypeItems.push(reportjson[reports[i]]);
      }
    }


  }
  async ionViewDidEnter() {
    this.subscription = this.platform.backButton.subscribe(async () => {
      if (this.menuController.isOpen()) {
        this.menuController.close()
      }
    });
  }
  ionViewWillLeave() {
    this.subscription.unsubscribe();
  }
  ngOnChanges() {
    this.plateNo = this.plateNo
    if (this.commonService.myPlatform == 'desktop') {
      this.openForm(this.reportName);
    }
    this.showaddress = localStorage.getItem('addressreport');///thas



  }





  reportEmit(x: any) {
    this.openForm(x)
  }



  ngOnInit() {
    console.warn("!!! MyPlatform  ( ( (", this.commonService.myPlatform, ") ) ) !!!")
    this.entryPoint = localStorage.getItem("entryPoint")
    this.plateNo = this.plateNo;
    // this.myPlatform = this.platform.platforms()[0];
    // if (this.myPlatform == 'tablet') {
    //   this.myPlatform = 'desktop';
    // }
    this.getAvailableReports();

    if (this.commonService.myPlatform == 'desktop') {
      this.openForm(this.reportTypeItems[0]);
    }
    this.openForm(this.reportName)

  }


}
