import { Component, Input, OnInit } from '@angular/core';
import { Platform } from '@ionic/angular';
import { companyReportData } from 'src/environments/environment';

@Component({
  selector: 'app-work-summary',
  templateUrl: './work-summary.component.html',
  styleUrls: ['./work-summary.component.scss'],
})
export class WorkSummaryComponent implements OnInit {
  commonData;
  @Input() displayCount: number = 30;
  displayData: Array<any>;
  currentPage: number = 1;
  myPlatform: string;
  data: any;
  constructor(
    private platform: Platform
  ) { }
  doInfinite(event) {
     setTimeout(() => {
       this.displayData.push(...this.commonData.slice(this.currentPage * this.displayCount, (this.currentPage + 1) * this.displayCount));
      this.currentPage++;
      event.target.complete();
      if (this.displayData.length == this.commonData.length) {
        event.target.disabled = true;
      }
    }, 500);
  }

  setDisplayData() {
    if(this.commonData){
    if (this.commonData.length > this.displayCount) {
      this.displayData = this.commonData.slice(0, this.displayCount);
    }
    else {
      this.displayData = this.commonData;
    }
  }
  }
  ngOnInit() { 
    this.commonData = companyReportData.reportData
    this.setDisplayData(); 
    this.myPlatform = this.platform.platforms()[0];
    // this.datas = this.commonData;
    if(this.myPlatform == 'tablet'){
      this.myPlatform = 'desktop';
    }
  }
  ngOnChanges() {
    this.setDisplayData();
    // this.datas = this.commonData;
    }
}
